// This rule will not be active when eslintrc of dashboard is upgraded.
/* eslint-disable import/prefer-default-export */

import { DayOfWeek, OpeningHour, TimeZoneDate } from '@caresend/types';
import { capitalize, militaryHourToTime } from '@caresend/utils';
import dayjs from 'dayjs';

import { buildBulletSeparatedString } from '@/functions/methods';

const dayjsToTime = (time: dayjs.Dayjs, displayTZ = false) => {
  const isAbridged = time.minute() === 0;

  const tz = displayTZ ? ' z' : '';
  const abridgedFormat = `h a${tz}`;
  const fullFormat = `h:mm a${tz}`;

  return isAbridged
    ? time.format(abridgedFormat)
    : time.format(fullFormat);
};

export const timeZoneDateToTime = (timeZoneDate: TimeZoneDate) => {
  const time = dayjs(timeZoneDate.timestamp).tz(timeZoneDate.timeZone);
  const displayTZ = (timeZoneDate.timeZone !== dayjs.tz.guess());
  return dayjsToTime(time, displayTZ);
};

export const numberToTime = (timeNumber: number): string => {
  const time = dayjs(timeNumber);
  return dayjsToTime(time);
};

export const formatDayOfWeek = (dayOfWeek: DayOfWeek, format: 'short' | 'long') => {
  if (format === 'short') {
    switch (dayOfWeek) {
      case (DayOfWeek.MONDAY):
      case (DayOfWeek.WEDNESDAY):
      case (DayOfWeek.FRIDAY):
        return capitalize(dayOfWeek)[0];
      case (DayOfWeek.TUESDAY):
      case (DayOfWeek.THURSDAY):
      case (DayOfWeek.SATURDAY):
      case (DayOfWeek.SUNDAY):
        return capitalize(dayOfWeek).substr(0, 2);
    }
  }
  return capitalize(dayOfWeek);
};

interface OpeningHourWithFormat {
  openingHour: OpeningHour;
  formattedTimes: string;
  formattedDays: string;
}

export interface FormattedOpeningHours {
  days: string;
  times: string;
}

export const formatOpeningHours = (
  openingHours: OpeningHour[],
  dayFormat: 'short' | 'long' = 'long',
): FormattedOpeningHours[] => {
  const groupedHours: Record<string, OpeningHourWithFormat[]> = {};

  const sortHourStrings = (a: OpeningHourWithFormat, b: OpeningHourWithFormat): number => {
    const aVal = Number(a.openingHour.timeFrom);
    const bVal = Number(b.openingHour.timeFrom);
    return aVal - bVal;
  };

  const sortDays = (a: OpeningHourWithFormat[], b: OpeningHourWithFormat[]) => {
    const aDayOfWeek = a[0]?.openingHour.dayFrom;
    const bDayOfWeek = b[0]?.openingHour.dayFrom;
    if (!aDayOfWeek && !bDayOfWeek) return 0;
    if (!aDayOfWeek) return 1;
    if (!bDayOfWeek) return -1;
    const aVal = Object.values(DayOfWeek).indexOf(aDayOfWeek);
    const bVal = Object.values(DayOfWeek).indexOf(bDayOfWeek);
    return aVal - bVal;
  };

  const formatGroupedHours = (hoursWithFormat: OpeningHourWithFormat[]): FormattedOpeningHours => {
    const days = hoursWithFormat[0]?.formattedDays ?? '';
    const times = buildBulletSeparatedString(hoursWithFormat.map(({ formattedTimes }) => formattedTimes));
    return { days, times };
  };

  const joiner = dayFormat === 'short' ? '-' : ' to ';

  openingHours.forEach((openingHour) => {
    const dayFrom = formatDayOfWeek(openingHour.dayFrom, dayFormat);
    const dayTo = formatDayOfWeek(openingHour.dayTo, dayFormat);
    const timeFrom = militaryHourToTime(Number(openingHour.timeFrom));
    const timeTo = militaryHourToTime(Number(openingHour.timeTo));
    const formattedDays = dayFrom === dayTo ? `${dayFrom}` : `${dayFrom}${joiner}${dayTo}`;
    const formattedTimes = `${timeFrom}${joiner}${timeTo}`;
    const hours = groupedHours[formattedDays] ?? [];
    hours.push({ openingHour, formattedTimes, formattedDays });
    hours.sort(sortHourStrings);
    groupedHours[formattedDays] = hours;
  });

  const formattedGroupedHours = Object.values(groupedHours)
    .sort(sortDays)
    .map(formatGroupedHours);

  return formattedGroupedHours;
};

export const formatDate = (
  date: TimeZoneDate | undefined,
  format: string,
  add: {duration: number; type: dayjs.ManipulateType } = { duration: 0, type: 'minutes' },
): string => {
  if (!date) return 'Unknown date';
  let formatSuffix = '';
  if (dayjs.tz.guess() !== date.timeZone) {
    formatSuffix = ' z';
  }
  return dayjs.utc(date.timestamp).tz(date.timeZone).add(add.duration, add.type).format(`${format}${formatSuffix}`);
};
