import {
  ByID,
  DbRef,
  Shift,
  ShiftStatus,
  ShiftTools,
} from '@caresend/types';
import {
  dbUpdate,
  firebaseBind,
  firebaseUnbind,
  getValueOnce,
  toastSuccess,
} from '@caresend/ui-components';
import {
  arrayToObj,
  deduplicateArray,
  formatShiftDateTime,
  initShift,
  nullishFilter,
} from '@caresend/utils';
import update from 'immutability-helper';

import { searchWithDispatch } from '@/database/algolia/store';
import {
  cleanShiftStateData,
  filterShiftsLocally,
  getShiftsAlgoliaSearch,
  initShiftTools,
  validateShiftStateData,
} from '@/store/modules/shifts/helpers';
import {
  AlgoliaShiftFilter,
  ShiftDateTimeInfo,
  ShiftVacancy,
  ShiftsActions,
  ShiftsState,
} from '@/store/modules/shifts/model';

type S = ShiftsState;

const initialData = () => ({
  dateTimeInfo: undefined,
  date: undefined,
  baseEarnings: 0,
  itineraryIDs: [],
  requiresOfflineSupplies: false,
  serviceRegionGroupID: undefined,
  serviceRegionIDs: [],
  taskIDs: [],
  tools: initShiftTools(),
});

const initialShiftsPage = () => ({
  algoliaFilter: AlgoliaShiftFilter.UPCOMING,
  fetchedShiftIDs: [],
  fetchTimestamp: 0,
  localFilters: {
    vacancy: [],
  },
  shiftsLoading: false,
});

const shiftsState: S = {
  data: initialData(),
  isAddModalOpen: false,
  shifts: null,
  shiftsPage: initialShiftsPage(),
};

const shiftsMutations = {
  'shifts/RESET_DATA': (state: S) => {
    state.data = initialData();
  },

  'shifts/SET_IS_ADD_SHIFT_MODAL_OPEN': (state: S, isOpen: boolean) => {
    state.isAddModalOpen = isOpen;
  },

  'shifts/SET_BASE_EARNINGS': (state: S, amount: number) => {
    state.data.baseEarnings = amount;
  },

  'shifts/SET_ITINERARY_IDS': (state: S, itineraryIDs: string[]) => {
    state.data.itineraryIDs = [...itineraryIDs];
  },

  'shifts/SET_NEW_SHIFT': (state: S, shift: Shift) => {
    state.shifts = update(state.shifts ?? {}, {
      [shift.id]: { $set: shift },
    });
  },

  'shifts/SET_REQUIRES_OFFLINE_SUPPLIES': (
    state: S,
    requiresOfflineSupplies: boolean,
  ) => {
    state.data.requiresOfflineSupplies = requiresOfflineSupplies;
  },

  'shifts/SET_SERVICE_REGION_GROUP_ID': (state: S, serviceRegionGroupID: string | undefined) => {
    state.data.serviceRegionGroupID = serviceRegionGroupID;
  },

  'shifts/SET_SERVICE_REGION_IDS': (state: S, serviceRegionIDs: string[]) => {
    state.data.serviceRegionIDs = [...serviceRegionIDs];
  },

  'shifts/SET_SHIFT_DATE_TIME_INFO': (state: S, newDateTimeInfo: ShiftDateTimeInfo) => {
    state.data.dateTimeInfo = update(state.data.dateTimeInfo, {
      $set: {
        startTime: newDateTimeInfo.startTime,
        endTime: newDateTimeInfo.endTime,
      },
    });
  },

  'shifts/SET_SHIFT_STATUS': (state: S, { shiftID, status }: { shiftID: string; status: ShiftStatus}) => {
    const shift = state.shifts?.[shiftID];
    if (!shift) return;

    state.shifts = update(state.shifts ?? {}, {
      [shiftID]: {
        status: { $set: status },
      },
    });
  },

  'shifts/SET_SHIFTS': (state: S, shifts: ByID<Shift> | null) => {
    state.shifts = update(state.shifts ?? {}, {
      $merge: shifts ?? {},
    });
  },

  'shifts/SET_SHIFTS_PAGE_ALGOLIA_FILTER': (state: S, filter: AlgoliaShiftFilter) => {
    state.shiftsPage.algoliaFilter = filter;
  },

  'shifts/SET_SHIFTS_PAGE_FETCH_TIMESTAMP': (state: S, timestamp: number) => {
    state.shiftsPage.fetchTimestamp = timestamp;
  },

  'shifts/SET_SHIFTS_PAGE_FETCHED_SHIFT_IDS': (state: S, ids: string[]) => {
    state.shiftsPage.fetchedShiftIDs = ids;
  },

  'shifts/SET_SHIFTS_PAGE_SHIFTS_LOADING': (state: S, loading: boolean) => {
    state.shiftsPage.shiftsLoading = loading;
  },

  'shifts/SET_SHIFTS_PAGE_VACANCY_FILTERS': (state: S, filters: ShiftVacancy[]) => {
    state.shiftsPage.localFilters.vacancy = filters;
  },

  'shifts/SET_SHIFT_TOOLS': (state: S, tools: ShiftTools) => {
    state.data.tools = tools;
  },

  'shifts/SET_TASKIDS': (state: S, taskIDs: string[]) => {
    state.data.taskIDs = [...taskIDs];
  },
};

const shiftsActions: ShiftsActions = {
  'shifts/bindShift': async ({ commit, dispatch }, { shiftID }) => {
    const shiftPath = `${DbRef.SHIFTS}/${shiftID}`;
    await firebaseBind<Shift>(shiftPath, (shift) => {
      if (!shift) return;

      if (shift.userID) {
        dispatch('users/bindUser', shift.userID);
      }

      const shiftsObj = arrayToObj([shift], 'id');
      commit('shifts/SET_SHIFTS', shiftsObj);
    });
  },

  /**
   * Supports fetching:
   * - Upcoming: The 300 soonest future shifts (oldest first - ascending)
   * - Past: The 300 most recent past shifts (newest first - descending)
   */
  'shifts/fetchShifts': async ({ commit, dispatch, state }) => {
    const fetchTimestamp = Date.now();
    commit('shifts/SET_SHIFTS_PAGE_FETCH_TIMESTAMP', fetchTimestamp);
    commit('shifts/SET_SHIFTS_PAGE_SHIFTS_LOADING', true);
    commit('shifts/SET_SHIFTS_PAGE_FETCHED_SHIFT_IDS', []);
    const { algoliaFilter } = state.shiftsPage;
    const shifts = await searchWithDispatch(
      dispatch,
      getShiftsAlgoliaSearch(algoliaFilter),
    );

    // Fetch Shift Dependencies
    const dependencyFetchPromises: Promise<void>[] = [];
    const userIDs = deduplicateArray(
      shifts.map((shift) => shift.userID).filter(nullishFilter),
    );
    dependencyFetchPromises.push(dispatch('users/fetchUsers', userIDs));
    const itineraryIDs = shifts.map((shift) =>
      Object.keys(shift.itineraries ?? {}),
    ).flat();
    dependencyFetchPromises.push(
      dispatch('itineraries/fetchItineraries', itineraryIDs),
    );
    await Promise.all(dependencyFetchPromises);

    const fetchedShiftIDs = shifts.map((shift) => shift.id);
    const shiftsObj = arrayToObj(shifts, 'id');

    // Prevent multiple request race condition
    if (state.shiftsPage.fetchTimestamp === fetchTimestamp) {
      commit('shifts/SET_SHIFTS', shiftsObj);
      commit('shifts/SET_SHIFTS_PAGE_FETCHED_SHIFT_IDS', fetchedShiftIDs);
      commit('shifts/SET_SHIFTS_PAGE_SHIFTS_LOADING', false);
    }
  },

  'shifts/hydrateShiftData': ({ commit, rootGetters, state }, { shiftID }) => {
    const shift = state.shifts?.[shiftID];
    if (!shift) return;

    const { endTime, startTime } = shift;
    const serviceRegionIDs = Object.keys(shift.serviceRegions ?? {});
    const serviceRegionGroupID = rootGetters[
      'variables/getServiceRegionGroupByServiceRegionID'
    ](serviceRegionIDs[0])?.id;
    const itineraryIDs = Object.keys(shift.itineraries ?? {});
    const taskIDs = Object.keys(shift.taskIDs ?? {});
    commit('shifts/SET_BASE_EARNINGS', shift.baseEarnings ?? 0);
    commit('shifts/SET_ITINERARY_IDS', itineraryIDs);
    commit('shifts/SET_SERVICE_REGION_IDS', serviceRegionIDs);
    commit('shifts/SET_SERVICE_REGION_GROUP_ID', serviceRegionGroupID);
    commit('shifts/SET_SHIFT_DATE_TIME_INFO', { startTime, endTime });
    commit('shifts/SET_SHIFT_TOOLS', shift.tools ?? initShiftTools());
    commit(
      'shifts/SET_REQUIRES_OFFLINE_SUPPLIES',
      shift.requiresOfflineSupplies ?? false,
    );
    commit('shifts/SET_TASKIDS', taskIDs);
  },

  'shifts/saveShift': async ({ commit, state }, shiftID) => {
    const { dateTimeInfo, serviceRegionIDs, taskIDs } = validateShiftStateData(
      state.data.dateTimeInfo,
      state.data.serviceRegionIDs,
      state.data.taskIDs,
    );

    const { serviceRegions, tasks } = cleanShiftStateData(serviceRegionIDs, taskIDs);
    const { baseEarnings, requiresOfflineSupplies, tools } = state.data;

    let shift: Shift | undefined;
    const isUpdate = !!shiftID;
    if (isUpdate) {
      const shiftToUpdate = await getValueOnce<Shift>(`${DbRef.SHIFTS}/${shiftID}`);
      if (!shiftToUpdate) throw new Error('Could not find shift to update');

      shift = {
        ...shiftToUpdate,
        baseEarnings: baseEarnings ?? 0,
        startTime: dateTimeInfo.startTime,
        endTime: dateTimeInfo.endTime,
        requiresOfflineSupplies,
        serviceRegions,
        tools,
        taskIDs: tasks,
      };
    } else {
      const initialShift = initShift('');
      shift = {
        ...initialShift,
        baseEarnings: baseEarnings ?? 0,
        startTime: dateTimeInfo.startTime,
        endTime: dateTimeInfo.endTime,
        requiresOfflineSupplies,
        serviceRegions,
        tools,
        taskIDs: tasks,
      };
    }

    if (!shift || !shift.id) throw Error(`Unable to ${isUpdate ? 'update' : 'create'} shift.`);
    const shiftPath = `${DbRef.SHIFTS}/${shift.id}`;
    await dbUpdate<Shift>(shiftPath, shift);
    toastSuccess(`Shift ${isUpdate ? 'updated' : 'created'}.`);
    commit('shifts/SET_NEW_SHIFT', shift);
    commit('shifts/SET_IS_ADD_SHIFT_MODAL_OPEN', false);
    return shift.id;
  },

  'shifts/unbindShift': (_ctx, { shiftID }) => {
    const shiftPath = `${DbRef.SHIFTS}/${shiftID}`;
    firebaseUnbind(shiftPath);
  },
};

const shiftsGetters = {
  'shifts/getShiftDetailsTitle': (state: S) =>
    (shiftID?: string) => {
      const shift = state.shifts?.[shiftID ?? ''];
      if (!shift) return 'Shift';

      const shiftTime = formatShiftDateTime(shift) ?? '';
      const shiftVacancy = shift.userID ? 'Taken' : 'Vacant';
      return `Shift for ${shiftTime} [${shiftVacancy}]`;
    },

  'shifts/getShiftByID': (state: S) =>
    (shiftID?: string): Shift | undefined => state.shifts?.[shiftID ?? ''],

  'shifts/getShiftsPageShiftList': (state: S): Shift[] => {
    const { fetchedShiftIDs, shiftsLoading, localFilters } = state.shiftsPage;
    if (shiftsLoading) return [];

    const fetchedShifts = fetchedShiftIDs.map((shiftID) =>
      state.shifts?.[shiftID],
    ).filter(nullishFilter);
    const locallyFilteredShifts = filterShiftsLocally(
      fetchedShifts,
      localFilters,
    );

    return locallyFilteredShifts;
  },
};

export type ShiftsGetters = typeof shiftsGetters;

export const shiftsModule = {
  state: shiftsState,
  mutations: shiftsMutations,
  actions: shiftsActions,
  getters: shiftsGetters,
};

export type ShiftsModule = typeof shiftsModule;
