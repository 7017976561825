import { ByID, PaginationRequest } from '@caresend/types';
import { PaginationInfo } from '@caresend/ui-components';

import { DEFAULT_PAGINATION_LIMIT } from '@/data/numbers';

export function arrayToObj <T>(array: T[], idKey: string): ByID<T>
export function arrayToObj <T>(array: T[], idKeyCallback: (item: T) => string): ByID<T>
export function arrayToObj <T>(array: T[], idKey: string | ((item: T) => string) = 'id'): ByID<T> {
  if (typeof idKey === 'string') {
    return Object.fromEntries(
      array.map((arrayItem, index) => {
        let key: string | unknown = index.toString();
        if (typeof arrayItem === 'object') {
          key = (arrayItem as Record<string, unknown>)[idKey];
        }
        return [key, arrayItem];
      }),
    );
  }
  return Object.fromEntries(
    array.map((arrayItem) => [idKey(arrayItem), arrayItem]),
  );
}

export const lenientStringIsEqual = (sr1: string, sr2: string) =>
  sr1.replaceAll(' ', '').toLowerCase() === sr2.replaceAll(' ', '').toLowerCase();

export const initPaginationState = (): PaginationRequest => ({
  page: 1,
  limit: DEFAULT_PAGINATION_LIMIT,
});

export const initPaginationInfo = (): PaginationInfo => ({
  limit: DEFAULT_PAGINATION_LIMIT,
  total: 0,
});
