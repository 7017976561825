import { GetAlgoliaAPIKeyResponse } from '@caresend/types';

export const needsNewKey = (keyData: GetAlgoliaAPIKeyResponse | null) => {
  const expiry = keyData?.expiry;
  if (!expiry) return true;
  const minute = 60 * 1000;
  return (Date.now() + minute) > expiry;
};

/**
 * This function will only work for objects also stored in algolia
 */
export const getObjectCreationTimestamp = (obj: any) => obj?.creationTimestamp;
