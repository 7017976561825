import { SearchClient, SearchOptions, SearchResponse } from '@algolia/client-search';
import { AlgoliaIndexSuffix, GetAlgoliaAPIKeyResponse, getAlgoliaEnv } from '@caresend/types';
import { isEmulatedDbAuth, isProd } from '@caresend/ui-components';
import { SearchIndex } from 'algoliasearch';

import type { CustomActionContext } from '@/store/model';

export const algoliaEnv = getAlgoliaEnv(isProd, isEmulatedDbAuth);

export const bookings = `${algoliaEnv}_${AlgoliaIndexSuffix.BOOKINGS}`;
export const draftWaypoints = `${algoliaEnv}_${AlgoliaIndexSuffix.DRAFT_WAYPOINTS}`;
export const draftWaypointsDescending = `${algoliaEnv}_${AlgoliaIndexSuffix.DRAFT_WAYPOINTS_DESCENDING}`;
export const feedback = `${algoliaEnv}_${AlgoliaIndexSuffix.FEEDBACK}`;
export const itineraries = `${algoliaEnv}_${AlgoliaIndexSuffix.ITINERARIES}`;
export const offices = `${algoliaEnv}_${AlgoliaIndexSuffix.OFFICES}`;
export const orders = `${algoliaEnv}_${AlgoliaIndexSuffix.ORDERS}`;
export const places = `${algoliaEnv}_${AlgoliaIndexSuffix.PLACES}`;
export const procedures = `${algoliaEnv}_${AlgoliaIndexSuffix.PROCEDURES}`;
export const shifts = `${algoliaEnv}_${AlgoliaIndexSuffix.SHIFTS}`;
export const shiftsDescending = `${algoliaEnv}_${AlgoliaIndexSuffix.SHIFTS_DESCENDING}`;
export const supplyShipments = `${algoliaEnv}_${AlgoliaIndexSuffix.SUPPLY_SHIPMENTS}`;
export const supplyTransfers = `${algoliaEnv}_${AlgoliaIndexSuffix.SUPPLY_TRANSFERS}`;
export const users = `${algoliaEnv}_${AlgoliaIndexSuffix.USERS}`;
export const waypoints = `${algoliaEnv}_${AlgoliaIndexSuffix.WAYPOINTS}`;

export type AlgoliaIndices = Partial<Record<AlgoliaIndexSuffix, SearchIndex>>;

export interface AlgoliaState {
  client: SearchClient | null;
  indices: AlgoliaIndices | null;
  keyData: GetAlgoliaAPIKeyResponse | null;
}

export interface AlgoliaSearch {
  index: AlgoliaIndexSuffix;
  params?: SearchOptions;
  query?: string;
}

export type AlgoliaActionContext = CustomActionContext<'algolia', AlgoliaState>

export type AlgoliaActions = {
  'algolia/search': (
    context: AlgoliaActionContext,
    payload: AlgoliaSearch,
  ) => Promise<SearchResponse['hits']>;

  'algolia/updateApiKey': (
    context: AlgoliaActionContext,
  ) => Promise<void>;
}
