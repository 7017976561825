import {
  augmentStore,
  initAuthWatches,
  initFirebaseAuthWatch,
  initOrderFlowWatches,
  isProd,
} from '@caresend/ui-components';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';

import { CustomModules, CustomStore, RootState } from '@/store/model';
import { algoliaModule } from '@/store/modules/algolia';
import { appModule } from '@/store/modules/app';
import { authModule, handleUserBound, handleUserUnbound } from '@/store/modules/auth';
import { bookingsModule } from '@/store/modules/bookings';
import { draftWaypointsModule } from '@/store/modules/draftWaypoints';
import { insuranceModule } from '@/store/modules/insurance';
import { itinerariesModule } from '@/store/modules/itineraries';
import { officesModule } from '@/store/modules/offices';
import { orderFlowModule } from '@/store/modules/orderFlow';
import { ordersModule } from '@/store/modules/orders';
import { placesModule } from '@/store/modules/places';
import { proceduresModule } from '@/store/modules/procedures';
import { schedulingFlowModule } from '@/store/modules/schedulingFlow';
import { shiftsModule } from '@/store/modules/shifts';
import { suppliesModule } from '@/store/modules/supplies';
import { testsModule } from '@/store/modules/tests';
import { usersModule } from '@/store/modules/users';
import { variablesModule } from '@/store/modules/variables';
import { waypointModule } from '@/store/modules/waypoint';

Vue.use(Vuex);

const vuexPersistence = new VuexPersistence<RootState>({
  reducer: (state) => ({
    auth: {
      authStatus: state.auth.authStatus,
      user: state.auth.user,
    },
    variables: {
      featureFlagOverrides: state.variables.featureFlagOverrides,
    },
  }),
  strictMode: !isProd,
});

const modules: CustomModules = {
  algolia: algoliaModule,
  app: appModule,
  auth: authModule,
  bookings: bookingsModule,
  draftWaypoints: draftWaypointsModule,
  insurance: insuranceModule,
  itineraries: itinerariesModule,
  offices: officesModule,
  orderFlow: orderFlowModule,
  orders: ordersModule,
  places: placesModule,
  procedures: proceduresModule,
  schedulingFlow: schedulingFlowModule,
  shifts: shiftsModule,
  supplies: suppliesModule,
  tests: testsModule,
  users: usersModule,
  variables: variablesModule,
  waypoint: waypointModule,
};

const storeOptions: StoreOptions<RootState> = {
  modules,
  mutations: {
    RESTORE_MUTATION: vuexPersistence.RESTORE_MUTATION,
  },
  plugins: [vuexPersistence.plugin],
  strict: !isProd,
};

/**
 * Rather than exporting the store, use `getStore()` to access the instance.
 */
const store: CustomStore = new Vuex.Store(storeOptions);

/**
 * `augmentStore` applies shared store logic that is not app-specific. It must
 * be called prior to using `getStore`.
 */
export const initStore = () => {
  augmentStore(store);

  initAuthWatches({
    handleUserBound,
    handleUserUnbound,
  });
  initFirebaseAuthWatch();
  initOrderFlowWatches(store);
};
