import '@/utils/initDevtools';
import '@/style/index.sass';
import 'focus-visible';

import { getRouter, getStore, initFirebase } from '@caresend/ui-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faAlignLeft,
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBarcode,
  faBars,
  faBirthdayCake,
  faBolt,
  faBox,
  faCalendarDay,
  faCarSide,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClinicMedical,
  faClipboardCheck,
  faClock,
  faCloud,
  faCloudUploadAlt,
  faCode,
  faCog,
  faCompress,
  faCopy,
  faDesktop,
  faDoorOpen,
  faDraftingCompass,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileExport,
  faFilePdf,
  faFlag,
  faFlask,
  faGlobeAmericas,
  faGripHorizontal,
  faHome,
  faIdBadge,
  faInfoCircle,
  faLanguage,
  faList,
  faLock,
  faLockOpen,
  faMap,
  faMapMarkedAlt,
  faMapMarker,
  faMapMarkerAlt,
  faPaperPlane,
  faPaperclip,
  faParachuteBox,
  faPaste,
  faPen,
  faPhone,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSave,
  faSearch,
  faSignInAlt,
  faStream,
  faSync,
  faSyringe,
  faTasks,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faTools,
  faTrash,
  faUndo,
  faUser,
  faUserAlt,
  faUserClock,
  faUserMd,
  faUserPlus,
  faWalking,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Buefy from 'buefy';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { PiniaVuePlugin, createPinia } from 'pinia';
import Vue from 'vue';
import VueMobileDetection from 'vue-mobile-detection';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import App from '@/App.vue';
import '@/registerServiceWorker';
import { initSegment, initializeSentry } from '@/functions/tracking';
import { initRouter } from '@/router';
import { initStore } from '@/store';

initFirebase();
initializeSentry();
initSegment();
initStore();
initRouter();

dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);

// TODO: determine why the import of VuePhoneNumberInput in this file
// is critical to a successful build, solve, then remove.
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

Vue.use(VueMobileDetection);

library.add(
  faAlignLeft,
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBarcode,
  faBars,
  faBirthdayCake,
  faBolt,
  faBox,
  faCalendarDay,
  faCarSide,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClinicMedical,
  faClipboardCheck,
  faClock,
  faCloud,
  faCloudUploadAlt,
  faCode,
  faCog,
  faCompress,
  faCopy,
  faDesktop,
  faDoorOpen,
  faDraftingCompass,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileExport,
  faFilePdf,
  faFlag,
  faFlask,
  faGlobeAmericas,
  faGoogle,
  faGripHorizontal,
  faHome,
  faIdBadge,
  faInfoCircle,
  faLanguage,
  faList,
  faLock,
  faLockOpen,
  faMap,
  faMapMarkedAlt,
  faMapMarker,
  faMapMarkerAlt,
  faPaperPlane,
  faPaperclip,
  faParachuteBox,
  faPaste,
  faPen,
  faPhone,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSave,
  faSearch,
  faSignInAlt,
  faStream,
  faSync,
  faUserClock,
  faSyringe,
  faTasks,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faTools,
  faTrash,
  faUndo,
  faUser,
  faUserAlt,
  faUserMd,
  faUserPlus,
  faWalking,
);
Vue.component('VueFontawesome', FontAwesomeIcon);
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

const initApp = async () => {
  await getStore().dispatch('app/initApp');
  initRouter();

  new Vue({
    name: 'Dashboard',
    router: getRouter(),
    store: getStore(),
    pinia,
    render: (h) => h(App),
  }).$mount('#app');
};

initApp();
