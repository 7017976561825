import {
  EncryptedFile,
  EncryptedUser,
  Feedback,
  FeedbackCategory,
  FeedbackChannel,
  FeedbackObjectType,
  FeedbackSubjectType,
  Note,
  NoteCategory,
  NoteObjectType,
  NoteSubjectType,
  OriginType,
  Role,
  StorageRef,
} from '@caresend/types';
import {
  Alert,
  FilePicker,
  Loading,
  Radio,
  RichSelect,
  RichSelectOption,
  TextInput,
  getStore,
} from '@caresend/ui-components';
import {
  capitalize,
  formatFullName,
  generateID,
  isFeedback,
  isFeedbackValid,
  isInEnum,
  isNote,
  isNoteValid,
  isNullish,
  nullishFilter,
} from '@caresend/utils';
import {
  PropType,
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';

import {
  getBookingOptions,
  getBookingOptionsByOrderID,
  getItineraryOptions,
  getOrderByItineraryID,
  getPlaceOptions,
  getPlaceOptionsByOrderID,
  getProcedureOptions,
  getProcedureOptionsByOrderID,
  getProductOptions,
  getProductOptionsByOrderID,
  getSupplyOptions,
  getSupplyOptionsByOrderID,
  getTaskOptions,
  getTaskOptionsByOrderID,
  getWaypointActionOptions,
  getWaypointActionOptionsByOrderID,
  getWaypointOptions,
  getWaypointOptionsByOrderID,
} from '@/views/dashboard/NurseDispatch/components/LegacyCreateItinerary/components/InputModal/helpers';
import {
  CategoryOption,
  IDOption,
  ObjectTypeOption,
  SubjectTypeOption,
} from '@/views/dashboard/NurseDispatch/components/LegacyCreateItinerary/components/InputModal/model';

const feedbackObjectTypeOptions: ObjectTypeOption[] = [
  { id: FeedbackObjectType.ITINERARY, label: 'Itinerary' },
  { id: FeedbackObjectType.PROCEDURE, label: 'Procedure' },
  { id: FeedbackObjectType.WAYPOINT, label: 'Waypoint' },
  { id: FeedbackObjectType.WAYPOINT_ACTION, label: 'Waypoint Action' },
];

const noteObjectTypeOptions: ObjectTypeOption[] = [
  { id: NoteObjectType.BOOKING, label: 'Booking' },
  { id: NoteObjectType.ITINERARY, label: 'Itinerary' },
  { id: NoteObjectType.ORDER, label: 'Order' },
  { id: NoteObjectType.PROCEDURE, label: 'Procedure' },
  { id: NoteObjectType.WAYPOINT, label: 'Waypoint' },
  { id: NoteObjectType.WAYPOINT_ACTION, label: 'Waypoint Action' },
];

const channelOptions = [
  { id: FeedbackChannel.EMAIL, label: 'Email' },
  { id: FeedbackChannel.SMS, label: 'Text' },
  { id: FeedbackChannel.PHONE, label: 'Phone' },
] as const;

const noteSubjectTypeOptions: SubjectTypeOption[] = [
  { id: NoteSubjectType.CARESEND, label: 'CareSend' },
  { id: NoteSubjectType.PLACE, label: 'Place' },
  { id: NoteSubjectType.SUPPLY, label: 'Supply' },
  { id: NoteSubjectType.TASK, label: 'Task' },
  { id: NoteSubjectType.TEST, label: 'Test' },
  { id: NoteSubjectType.USER, label: 'User' },
];

const feedbackSubjectTypeOptions: SubjectTypeOption[] = [
  { id: FeedbackSubjectType.PLACE, label: 'Place' },
  { id: FeedbackSubjectType.SUPPLY, label: 'Supply' },
  { id: FeedbackSubjectType.USER, label: 'User' },
  { id: FeedbackSubjectType.CARESEND, label: 'CareSend' },
];

const ratingOptions = [
  { id: 0, label: '0' },
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },
] as const;

const feedbackCategoryOptions: CategoryOption[] = [
  { id: FeedbackCategory.ACCESS_INSTRUCTIONS, label: 'Access Instructions' },
  { id: FeedbackCategory.COMMUNICATION, label: 'Communication' },
  { id: FeedbackCategory.EXPERIENCE, label: 'Experience' },
  { id: FeedbackCategory.MEDICAL_SERVICE, label: 'Medical Service' },
  { id: FeedbackCategory.ONBOARDING, label: 'Onboarding' },
  { id: FeedbackCategory.SUPPLIES_MALFUNCTION, label: 'Supplies Malfunction' },
  { id: FeedbackCategory.SUPPLIES_MISSING, label: 'Supplies Missing' },
  { id: FeedbackCategory.TECH, label: 'Tech' },
];

const noteCategoryOptions: CategoryOption[] = [
  { id: NoteCategory.ACCESS_ISSUE, label: 'Access Issue' },
  { id: NoteCategory.ERROR, label: 'Error' },
  { id: NoteCategory.FULFILLMENT_ISSUE, label: 'Fulfillment Issue' },
  { id: NoteCategory.MEDICAL_ORDER, label: 'Medical Order' },
  { id: NoteCategory.SAMPLE_LABELS_OK, label: 'Sample Labels OK' },
  { id: NoteCategory.SAMPLE_LABEL_MISSPELLED, label: 'Sample Label Misspelled' },
  { id: NoteCategory.SAMPLE_NOT_LABELED, label: 'Sample Not Labeled' },
  { id: NoteCategory.SCHEDULING, label: 'Scheduling' },
  { id: NoteCategory.SUPPLY_ISSUE, label: 'Supply Issue' },
  { id: NoteCategory.TECH_ISSUE, label: 'Tech Issue' },
];

export enum InputType {
  FEEDBACK = 'feedback',
  NOTE = 'note',
  QUICK_NOTE = 'quickNote',
}

export interface UserOption {
  id: string;
  label: string;
  role: Role;
}

export const componentDefinition = defineComponent({
  name: 'InputModal',
  components: {
    Alert,
    Loading,
    Radio,
    RichSelect,
    TextInput,
    FilePicker,
  },
  props: {
    isOpened: Boolean,
    itineraryID: {
      type: String as PropType<string | null>,
      default: null,
    },
    orderID: {
      type: String as PropType<string | null>,
      default: null,
    },
    inputType: {
      type: String as PropType<InputType>,
      default: InputType.FEEDBACK,
    },
  },
  setup(props, { emit }) {
    const store = getStore();
    const storagePath = StorageRef.NOTE_FILES;

    /** Loading required data (full page loader) */
    const isLoading = ref<boolean>(false);
    /** Saving in progress (save button loader) */
    const saveLoading = ref<boolean>(false);
    const id = ref<string>(generateID());
    const feedback = computed<boolean>(() => props.inputType === InputType.FEEDBACK);
    const note = computed<boolean>(() =>
      props.inputType === InputType.NOTE || props.inputType === InputType.QUICK_NOTE,
    );

    const originType = computed<OriginType | undefined>(() => {
      if (props.itineraryID) {
        return OriginType.ITINERARY;
      }
      if (props.orderID) {
        return OriginType.ORDER;
      }
    });

    const heading = computed<string>(() => {
      switch (props.inputType) {
        case (InputType.FEEDBACK): {
          return 'Complete Survey';
        }
        case (InputType.NOTE): {
          return 'Add Note';
        }
        case (InputType.QUICK_NOTE): {
          return 'Add Quick Note';
        }
      }
    });

    const users = computed<EncryptedUser[] | undefined>(() => {
      if (props.itineraryID) {
        return store.getters[
          'itineraries/getUsersOnItinerary'
        ](props.itineraryID);
      }
      if (props.orderID) {
        return store.getters[
          'orders/getUsersOnOrder'
        ](props.orderID);
      }
    });

    const userOptions = computed<UserOption[]>(() => {
      if (isNullish(users.value)) return [];
      return users.value.map((user) => ({
        id: user.id,
        label: `${formatFullName(user)}, ${capitalize(user.role)}`,
        role: user.role,
      })).filter(nullishFilter);
    });

    const submitterSelection = ref<
    RichSelectOption & { role: Role } | undefined
    >(undefined);
    const resetSubmitterSelection = () => {
      submitterSelection.value = undefined;
    };

    const objectTypeOptions = computed<ObjectTypeOption[]>(() => {
      if (props.itineraryID) {
        switch (props.inputType) {
          case (InputType.FEEDBACK): {
            return feedbackObjectTypeOptions;
          }
          case (InputType.NOTE):
          case (InputType.QUICK_NOTE): {
            return noteObjectTypeOptions;
          }
        }
      }
      if (props.orderID) {
        switch (props.inputType) {
          case (InputType.FEEDBACK): {
            return feedbackObjectTypeOptions;
          }
          case (InputType.NOTE):
          case (InputType.QUICK_NOTE): {
            return noteObjectTypeOptions;
          }
        }
      }
      return [];
    });

    const objectTypeSelection = ref<ObjectTypeOption | undefined>();
    const resetObjectTypeSelection = () => {
      objectTypeSelection.value = undefined;
    };

    const objectIdOptions = computed<IDOption[]>(() => {
      if (isNullish(objectTypeSelection.value)) return [];
      if (props.itineraryID) {
        switch (objectTypeSelection.value.id) {
          case FeedbackObjectType.ITINERARY:
          case NoteObjectType.ITINERARY:
            return [{ id: props.itineraryID, label: props.itineraryID }];
          case FeedbackObjectType.PROCEDURE:
          case NoteObjectType.PROCEDURE:
            return getProcedureOptions(props.itineraryID);
          case FeedbackObjectType.WAYPOINT:
          case NoteObjectType.WAYPOINT:
            return getWaypointOptions(props.itineraryID);
          case FeedbackObjectType.WAYPOINT_ACTION:
          case NoteObjectType.WAYPOINT_ACTION:
            return getWaypointActionOptions(props.itineraryID);
          case NoteObjectType.BOOKING:
            return getBookingOptions(props.itineraryID);
          case NoteObjectType.ORDER:
            return getOrderByItineraryID(props.itineraryID);
        }
      }
      if (props.orderID) {
        switch (objectTypeSelection.value.id) {
          case FeedbackObjectType.ITINERARY:
          case NoteObjectType.ITINERARY:
            return getItineraryOptions(props.orderID);
          case FeedbackObjectType.PROCEDURE:
          case NoteObjectType.PROCEDURE:
            return getProcedureOptionsByOrderID(props.orderID);
          case FeedbackObjectType.WAYPOINT:
          case NoteObjectType.WAYPOINT:
            return getWaypointOptionsByOrderID(props.orderID);
          case FeedbackObjectType.WAYPOINT_ACTION:
          case NoteObjectType.WAYPOINT_ACTION:
            return getWaypointActionOptionsByOrderID(props.orderID);
          case NoteObjectType.BOOKING:
            return getBookingOptionsByOrderID(props.orderID);
          case NoteObjectType.ORDER:
            return [{ id: props.orderID, label: props.orderID }];
        }
      }
      return [];
    });
    const objectIdSelection = ref<IDOption | undefined>(undefined);
    // Itinerary option does not allow selection as there is only one
    // itinerary to reference.
    const objectIdDisabled = computed<boolean>(() =>
      objectIdSelection.value?.id === FeedbackObjectType.ITINERARY
      || objectIdSelection.value?.id === NoteObjectType.ITINERARY,
    );
    const resetObjectIdSelection = () => {
      objectIdSelection.value = undefined;
    };
    watch(() => objectTypeSelection.value, () => {
      resetObjectIdSelection();
    });

    const subjectTypeOptions = computed<SubjectTypeOption[]>(() => {
      switch (props.inputType) {
        case (InputType.FEEDBACK): {
          return feedbackSubjectTypeOptions;
        }
        case (InputType.NOTE):
        case (InputType.QUICK_NOTE): {
          return noteSubjectTypeOptions;
        }
      }
    });

    const subjectTypeSelection = ref<SubjectTypeOption | undefined>(undefined);
    const resetSubjectTypeSelection = () => {
      subjectTypeSelection.value = undefined;
    };

    const subjectIdOptions = computed<IDOption[]>(() => {
      if (isNullish(subjectTypeSelection.value)) return [];
      if (props.itineraryID) {
        switch (subjectTypeSelection.value.id) {
          case FeedbackSubjectType.PLACE:
          case NoteSubjectType.PLACE:
            return getPlaceOptions(props.itineraryID);
          case FeedbackSubjectType.SUPPLY:
          case NoteSubjectType.SUPPLY:
            return getSupplyOptions(props.itineraryID);
          case FeedbackSubjectType.USER:
          case NoteSubjectType.USER:
            return userOptions.value;
          case FeedbackSubjectType.CARESEND:
          case NoteSubjectType.CARESEND:
            return [{ id: 'CareSend', label: 'CareSend' }];
          case NoteSubjectType.TASK:
            return getTaskOptions(props.itineraryID);
          case NoteSubjectType.TEST:
            return getProductOptions(props.itineraryID);
        }
      }
      if (props.orderID) {
        switch (subjectTypeSelection.value.id) {
          case FeedbackSubjectType.PLACE:
          case NoteSubjectType.PLACE:
            return getPlaceOptionsByOrderID(props.orderID);
          case FeedbackSubjectType.SUPPLY:
          case NoteSubjectType.SUPPLY:
            return getSupplyOptionsByOrderID(props.orderID);
          case FeedbackSubjectType.USER:
          case NoteSubjectType.USER:
            return userOptions.value;
          case FeedbackSubjectType.CARESEND:
          case NoteSubjectType.CARESEND:
            return [{ id: 'CareSend', label: 'CareSend' }];
          case NoteSubjectType.TASK:
            return getTaskOptionsByOrderID(props.orderID);
          case NoteSubjectType.TEST:
            return getProductOptionsByOrderID(props.orderID);
        }
      }
      return [];
    });
    const subjectIdSelection = ref<IDOption | undefined>(undefined);
    const subjectIdDisabled = computed<boolean>(() =>
      subjectTypeSelection.value?.id === FeedbackSubjectType.CARESEND
      || subjectTypeSelection.value?.id === NoteSubjectType.CARESEND,
    );
    const resetSubjectIdSelection = () => {
      subjectIdSelection.value = undefined;
    };
    const subjectTypeCaresend = computed(
      () => subjectTypeSelection.value?.id === NoteSubjectType.CARESEND
      || subjectTypeSelection.value?.id === FeedbackSubjectType.CARESEND,
    );
    watch(() => subjectTypeSelection.value, () => {
      if (!subjectTypeCaresend.value) {
        resetSubjectIdSelection();
      } else {
        subjectIdSelection.value = { id: 'CareSend', label: 'CareSend' };
      }
    });

    const ratingSelection = ref<typeof ratingOptions[number] | null>(null);
    const resetRatingSelection = () => { ratingSelection.value = null; };

    const categoryOptions = computed<CategoryOption[]>(() => {
      switch (props.inputType) {
        case (InputType.FEEDBACK): {
          return feedbackCategoryOptions;
        }
        case (InputType.NOTE):
        case (InputType.QUICK_NOTE): {
          return noteCategoryOptions;
        }
      }
    });

    const categorySelection = ref<CategoryOption | undefined>(undefined);
    const resetCategorySelection = () => {
      categorySelection.value = undefined;
    };

    const freeText = ref<string>('');
    const resetFreeText = () => { freeText.value = ''; };

    const selectedChannelID = ref<FeedbackChannel | undefined>(undefined);
    const resetSelectedChannelID = () => {
      selectedChannelID.value = undefined;
    };

    const files = ref<EncryptedFile[]>([]);
    const timeSpent = ref<number | undefined>(undefined);

    const resetAll = () => {
      resetSubmitterSelection();
      resetObjectTypeSelection();
      resetObjectIdSelection();
      resetSubjectTypeSelection();
      resetSubjectIdSelection();
      resetRatingSelection();
      resetCategorySelection();
      resetFreeText();
      resetSelectedChannelID();
      files.value = [];
      timeSpent.value = undefined;
    };

    const initQuickNote = () => {
      objectTypeSelection.value = objectTypeOptions.value.find((option) => option.id === NoteObjectType.ITINERARY);
      subjectTypeSelection.value = subjectTypeOptions.value.find((option) => option.id === NoteSubjectType.CARESEND);
    };

    // Treat modal open like mounting
    // Fetch needed data and reset
    watch(() => props.isOpened, async (isOpened) => {
      if (isOpened) {
        isLoading.value = true;
        id.value = generateID();
        if (props.itineraryID) {
          const placeIDs = store.getters[
            'itineraries/getPlaceIDsOnItinerary'
          ](props.itineraryID);
          await store.dispatch('places/fetchPlaces', {
            placeIDs,
            includePlaceGroups: true,
          });
        }
        resetAll();
        if (props.inputType === InputType.QUICK_NOTE) {
          initQuickNote();
        }
        isLoading.value = false;
      }
    });

    const draft = computed<Feedback | Note | undefined>(() => {
      const originID = props.itineraryID ?? props.orderID;
      if (
        isNullish(originID)
        || isNullish(categorySelection.value)
        || isNullish(objectIdSelection.value)
        || isNullish(objectIdSelection.value.id)
        || isNullish(objectTypeSelection.value)
        || isNullish(objectTypeSelection.value.id)
        || isNullish(subjectIdSelection.value)
        || isNullish(subjectIdSelection.value.id)
        || isNullish(subjectTypeSelection.value)
        || isNullish(subjectTypeSelection.value.id)
        || isNullish(originType.value)
        || freeText.value === ''
      ) return;
      if (
        isInEnum<FeedbackCategory>(categorySelection.value.id, FeedbackCategory)
        && isInEnum<FeedbackSubjectType>(subjectTypeSelection.value?.id, FeedbackSubjectType)
        && isInEnum<FeedbackObjectType>(objectTypeSelection.value?.id, FeedbackObjectType)
      ) {
        if (isNullish(submitterSelection.value)) return;
        if (isNullish(submitterSelection.value.id)) return;
        if (isNullish(selectedChannelID.value)) return;
        const feedbackDraft: Feedback = {
          channel: selectedChannelID.value,
          creationTimestamp: Date.now(),
          feedbackCategory: categorySelection.value.id,
          freeText: freeText.value,
          id: id.value,
          feedbackObjectID: objectIdSelection.value.id,
          feedbackObjectType: objectTypeSelection.value?.id,
          originType: originType.value,
          originID,
          ...(
            !isNullish(ratingSelection.value?.id)
              ? { rating: ratingSelection.value?.id }
              : undefined
          ),
          subjectID: subjectIdSelection.value?.id,
          subjectType: subjectTypeSelection.value?.id,
          /** Encrypt if patient before saving. */
          submitterID: submitterSelection.value?.id,
          submitterRole: submitterSelection.value?.role,
        };
        return feedbackDraft;
      } if (
        isInEnum<NoteObjectType>(objectTypeSelection.value?.id, NoteObjectType)
        && isInEnum<NoteSubjectType>(subjectTypeSelection.value?.id, NoteSubjectType)
        && isInEnum<NoteCategory>(categorySelection.value.id, NoteCategory)
      ) {
        const noteDraft: Note = {
          category: categorySelection.value?.id,
          creationTimestamp: Date.now(),
          freeText: freeText.value,
          id: id.value,
          noteObjectID: objectIdSelection.value?.id,
          objectType: objectTypeSelection.value?.id,
          originID,
          originType: originType.value,
          subjectID: subjectIdSelection.value?.id,
          subjectType: subjectTypeSelection.value?.id,
        };
        if (files.value) {
          noteDraft.files = files.value;
        }
        if (props.itineraryID) {
          noteDraft.itineraryID = props.itineraryID;
        }
        if (timeSpent.value) {
          noteDraft.timeSpent = timeSpent.value;
        }
        return noteDraft;
      }
    });

    const valid = computed<boolean | undefined>(() => {
      if (isNullish(draft.value)) return false;
      if (isFeedback(draft.value)) {
        return isFeedbackValid(draft.value);
      } if (isNote(draft.value)) {
        return isNoteValid(draft.value);
      }
    });

    const saveFeedback = async () => {
      saveLoading.value = true;
      let success = false;
      if (isFeedback(draft.value)) {
        /** TODO: refactor to use itinerary store */
        success = await store.dispatch(
          'itineraries/saveItineraryFeedback',
          draft.value,
        );
      } else if (isNote(draft.value)) {
        /** TODO: refactor to use itinerary store */
        success = await store.dispatch(
          'itineraries/saveNote',
          draft.value,
        );
      }

      saveLoading.value = false;
      if (success) emit('close');
    };

    return {
      channelOptions,
      freeText,
      isLoading,
      ratingOptions,
      objectIdDisabled,
      ratingSelection,
      resetCategorySelection,
      resetObjectIdSelection,
      resetRatingSelection,
      resetSubjectIdSelection,
      resetSubjectTypeSelection,
      saveFeedback,
      saveLoading,
      selectedChannelID,
      storagePath,
      subjectIdDisabled,
      subjectIdOptions,
      subjectIdSelection,
      subjectTypeOptions,
      subjectTypeSelection,
      submitterSelection,
      objectIdOptions,
      objectIdSelection,
      feedbackObjectTypeOptions,
      valid,
      userOptions,
      heading,
      objectTypeOptions,
      objectTypeSelection,
      resetObjectTypeSelection,
      categoryOptions,
      categorySelection,
      files,
      timeSpent,
      feedback,
      note,
      draft,
    };
  },
});
