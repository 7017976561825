import { ByID, Procedure } from '@caresend/types';
import {
  AnyGetters,
  BookingsModule,
  BookingsState,
  ExtendedCustomModule,
  OrdersActions,
  UsersActions,
  UsersGetters,
  UsersMutations,
  initBookingsModule,
} from '@caresend/ui-components';
import { nullishFilter, objectFilter, objectMap } from '@caresend/utils';

import type { RootState } from '@/store/model';

type S = BookingsState;

const extendedBookingsGetters = {
  /** getBookingProcedures is used to resolve backwards compatibility issues */
  'bookings/getBookingProcedures': (state: S, _getters: AnyGetters, rootState: RootState) => (
    bookingID: string,
  ): ByID<Procedure> | undefined => {
    const booking = state.bookings?.[bookingID];
    if (!booking) return;

    return objectFilter(
      objectMap(
        booking.procedures ?? {},
        ({ id }) => rootState.procedures.procedures?.[id],
      ),
      nullishFilter,
    );
  },

  'bookings/getBookingOrderID': (state: S) => (
    bookingID: string,
  ): string | undefined => state.bookings?.[bookingID]?.orderID,
};

export const bookingsModuleExtension = {
  getters: extendedBookingsGetters,
};

export const bookingsModule: ExtendedCustomModule<
  BookingsModule<
    UsersMutations,
    UsersActions & OrdersActions,
    UsersGetters
  >,
  typeof bookingsModuleExtension
> = initBookingsModule(bookingsModuleExtension);

export type ExtendedBookingsModule = typeof bookingsModule;
export type ExtendedBookingsMutations = ExtendedBookingsModule['mutations'];
export type ExtendedBookingsActions = ExtendedBookingsModule['actions'];
export type ExtendedBookingsGetters = ExtendedBookingsModule['getters'];
