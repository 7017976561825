import {
  ByID,
  IdObj,
  Itinerary,
  Place,
  PlaceAccount,
  ServiceRegion,
  Waypoint,
  WaypointAction,
  WaypointActionType,
} from '@caresend/types';

import { InjectedNulls } from '@/store/modules/itinerary/utils/injectNulls';

export const DEFAULT_ROUTE_DURATION = 30;

export interface PlaceOption {
  placeAccount: PlaceAccount;
  place: Place;
}

export const durationsByWaypointActionType: Record<WaypointActionType, number> = {
  [WaypointActionType.PICKUP]: 15,
  [WaypointActionType.DROPOFF]: 5,
  [WaypointActionType.PATIENT_ACTION]: 45,
  [WaypointActionType.MAIL_IN]: 5,
  [WaypointActionType.SAMPLE]: 0,
};

/**
 * To display a draft waypoint in the list as closely as possible to a created waypoint,
 * we create a preview of the waypoint and waypoint actions that would be added.
 * These are managed by the waypoints and waypointActions record managers and
 * these are ultimately the waypoint and waypoint actions that would be added to the itinerary.
 */
export interface WaypointPreview {
  combinableWaypointIDs: string[];
  waypoint: Waypoint;
  waypointActions: WaypointAction[];
  draftWaypointID: string;
}

export type ItinerarySetters = {
[K in keyof InjectedNulls<Itinerary>]: (
    value: Itinerary[K],
    /**
     * If true, queues an update for the itinerary.
     * If a string, uses that as the update label.
     * If false, does not queue an update.
     */
    queueUpdate?: string | boolean,
  ) => void;
} & {
  value: (itineraryID: Itinerary) => void;
}
export type TargetOrID<T> = T | string;

/** Stored in cache record manager by itineraryID or Waypoint location hash */
export interface ServiceRegionCache extends IdObj {
  serviceRegions: ByID<ServiceRegion>;
}
