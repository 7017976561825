import { ByID, Shift, ShiftTools, TimeZoneDate } from '@caresend/types';

import type { CustomActionContext } from '@/store/model';

export enum AlgoliaShiftFilter {
  PAST = 'past',
  UPCOMING = 'upcoming',
}

export enum ShiftVacancy {
  TAKEN = 'taken',
  VACANT = 'vacant'
}

export interface ShiftDateTimeInfo {
  startTime: TimeZoneDate;
  endTime: TimeZoneDate;
}

export interface ShiftStateData {
  dateTimeInfo: ShiftDateTimeInfo | undefined;
  baseEarnings: number;
  itineraryIDs: string[];
  requiresOfflineSupplies: boolean;
  serviceRegionGroupID: string | undefined;
  serviceRegionIDs: string[];
  taskIDs: string[];
  tools: ShiftTools;
}

export interface LocalShiftFilters {
  vacancy: ShiftVacancy[];
}

export interface ShiftPageState {
  algoliaFilter: AlgoliaShiftFilter;
  fetchedShiftIDs: string[];
  localFilters: LocalShiftFilters;
  /** For preventing race conditions */
  fetchTimestamp: number;
  shiftsLoading: boolean;
}

export interface ShiftsState {
  data: ShiftStateData;
  isAddModalOpen: boolean;
  shiftsPage: ShiftPageState;
  shifts: ByID<Shift> | null;
}

export type ShiftsActionContext = CustomActionContext<'shifts', ShiftsState>

export type ShiftsActions = {
  'shifts/bindShift': (
    context: ShiftsActionContext,
    payload: {
      shiftID: string;
    }
  ) => Promise<void>;

  'shifts/fetchShifts': (
    context: ShiftsActionContext,
  ) => Promise<void>;

  'shifts/hydrateShiftData': (
    context: ShiftsActionContext,
    payload: {
      shiftID: string;
    }
  ) => void;

  'shifts/saveShift': (
    context: ShiftsActionContext,
    shiftID?: string,
  ) => Promise<string>;

  'shifts/unbindShift': (
    context: ShiftsActionContext,
    payload: {
      shiftID: string;
    }
  ) => void;
}
