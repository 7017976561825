import { SearchOptions } from '@algolia/client-search';
import {
  AlgoliaDraftWaypointTag,
  AlgoliaIndexSuffix,
  DraftWaypoint,
  Waypoint,
  WaypointAction,
} from '@caresend/types';
import { DraftWaypointCardData } from '@caresend/ui-components';
import { arrayToObj, nullishFilter } from '@caresend/utils';

import { CoordinateBounds, getLocationNumericFilters } from '@/database/algolia/model';
import type { RootState } from '@/store/model';
import { AlgoliaSearch } from '@/store/modules/algolia/model';
import { WaypointSupplyListTab } from '@/views/dashboard/WaypointSupplyList/model';

export const generateDraftWaypointParams = (
  tag?: AlgoliaDraftWaypointTag,
  coordinateBounds?: CoordinateBounds,
): SearchOptions => {
  let filters = '';
  let numericFilters: string[] = [];

  if (tag) filters = `_tags:${tag}`;

  if (coordinateBounds) {
    numericFilters = getLocationNumericFilters('location', coordinateBounds);
  }

  return {
    filters,
    numericFilters,
    hitsPerPage: 40,
  };
};

export const getDraftWaypointCardData = (
  draftWaypoint: DraftWaypoint,
  rootState: RootState,
): DraftWaypointCardData => {
  const procedureIDs = Object.keys(draftWaypoint.procedures);
  const procedures = procedureIDs.map((id) =>
    rootState.procedures.procedures[id],
  ).filter(nullishFilter);
  const anyProcedureQAPending = procedures.some((procedure) => !procedure.qualityControl?.intake);
  const bookingIDs = procedures.map((procedure) => procedure.bookingID);
  const bookings = bookingIDs.map((id) =>
    rootState.bookings.bookings[id],
  ).filter(nullishFilter);
  const patientIDs = bookings.map((booking) => booking.patientID)
    .filter(nullishFilter);
  const patients = patientIDs.map((id) => rootState.users.users[id])
    .filter(nullishFilter);
  const orderIDs = bookings.map((booking) => booking.orderID);
  const orders = orderIDs.map((id) => rootState.orders.orders[id])
    .filter(nullishFilter);
  const prescriberIDs = orders.map((order) => order.prescriberID)
    .filter(nullishFilter);
  const prescribers = prescriberIDs.map((id) => rootState.users.users[id])
    .filter(nullishFilter);
  const users = [...patients, ...prescribers];

  let waypoint: Waypoint | undefined;
  if (draftWaypoint.waypointID) {
    waypoint = rootState.itineraries.waypoints?.[draftWaypoint.waypointID];
  }

  return {
    anyProcedureQAPending,
    bookings: arrayToObj(bookings, 'id'),
    draftWaypoint,
    orders: arrayToObj(orders, 'id'),
    procedures: arrayToObj(procedures, 'id'),
    tasks: rootState.variables.variables?.tasks ?? {},
    users: arrayToObj(users, 'id'),
    waypoint,
  };
};

export const getDraftWaypointCardsData = (
  draftWaypoints: DraftWaypoint[],
  rootState: RootState,
): DraftWaypointCardData[] =>
  draftWaypoints.map((draftWaypoint) =>
    getDraftWaypointCardData(draftWaypoint, rootState),
  );

/**
 * Reducer to calculate total duration for a list of waypoint actions
 */
export const wpaReducer = (acc: number, curr: WaypointAction): number =>
  acc + curr.duration.actionDuration;

const getPastDraftWaypointFilter = (): string =>
  `finalizedDate.date.timestamp <= ${Date.now()} AND `
  + `_tags:${AlgoliaDraftWaypointTag.SHOW_IN_SUPPLY_TRANSFERS_LIST}`;
const getUpcomingDraftWaypointFilter = (): string =>
  `finalizedDate.date.timestamp > ${Date.now()} AND `
  + `_tags:${AlgoliaDraftWaypointTag.SHOW_IN_SUPPLY_TRANSFERS_LIST}`;

type DraftWaypointAlgoliaSearch = AlgoliaSearch
  & { index: AlgoliaIndexSuffix.DRAFT_WAYPOINTS | AlgoliaIndexSuffix.DRAFT_WAYPOINTS_DESCENDING }

/**
 * DRAFT_WAYPOINT_DESCENDING is sorted by finalizedDate descending.
 *   - Past: Get the most recent 300 draftWaypoints.
 * DRAFT_WAYPOINT is sorted by finalizedDate ascending.
 *   - Upcoming: Get the next 300 draftWaypoints.
 */
export const getDraftWaypointAlgoliaSearch = (
  currentTab: WaypointSupplyListTab,
): DraftWaypointAlgoliaSearch => {
  switch (currentTab) {
    case WaypointSupplyListTab.PAST:
      return {
        index: AlgoliaIndexSuffix.DRAFT_WAYPOINTS_DESCENDING,
        params: { filters: getPastDraftWaypointFilter() },
      };
    case WaypointSupplyListTab.CURRENT:
      return {
        index: AlgoliaIndexSuffix.DRAFT_WAYPOINTS,
        params: { filters: getUpcomingDraftWaypointFilter() },
      };
  }
};
