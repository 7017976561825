import {
  AlgoliaIndexSuffix,
  ByID,
  DbRef,
  DraftWaypoint,
  RequestWithRelationships,
  Waypoint,
  WaypointAction,
} from '@caresend/types';
import { arrayToObj, fetchWithRelationships, nullishFilter, stripAlgoliaProperties } from '@caresend/utils';
import update from 'immutability-helper';

import { searchWithDispatch } from '@/database/algolia/store';
import { copyDraftWaypointRequest } from '@/database/firebase/API';
import { generateDraftWaypointParams } from '@/store/modules/draftWaypoints/helpers';
import {
  DraftWaypointsActions,
  DraftWaypointsState,
  DraftWaypointsStateData,
} from '@/store/modules/draftWaypoints/model';

type S = DraftWaypointsState;

const initDraftWaypointsStateData = (): DraftWaypointsStateData => ({
  selectedDate: undefined,
});

const initialDraftWaypointsForRescheduling = () => ({
  draftWaypointIDs: [],
});

const draftWaypointsState: S = {
  data: initDraftWaypointsStateData(),
  draftWaypointsForRescheduling: initialDraftWaypointsForRescheduling(),
  draftWaypoints: {},
  previews: {
    waypoints: {},
    waypointActions: {},
  },
};

const draftWaypointsMutations = {
  'draftWaypoints/SET_DRAFT_WAYPOINT_IN_OBJ_AND_FOR_RESCHEDULING': (
    state: S,
    draftWaypoint: DraftWaypoint,
  ) => {
    const { id } = draftWaypoint;
    state.draftWaypoints = update(state.draftWaypoints, {
      [id]: { $set: draftWaypoint },
    });

    const reschedulingIDs = state.draftWaypointsForRescheduling.draftWaypointIDs;

    // Add or move draft waypoint to top of list.
    if (reschedulingIDs.includes(id)) {
      const reschedulingIDsWithoutID = reschedulingIDs.filter((listId) => listId !== id);
      state.draftWaypointsForRescheduling.draftWaypointIDs = [id, ...reschedulingIDsWithoutID];
    } else {
      state.draftWaypointsForRescheduling.draftWaypointIDs = [id, ...reschedulingIDs];
    }
  },

  'draftWaypoints/SET_DRAFT_WAYPOINTS': (state: S, draftWaypoints: ByID<DraftWaypoint>) => {
    state.draftWaypoints = {
      ...state.draftWaypoints,
      ...draftWaypoints,
    };
  },

  'draftWaypoints/SET_DRAFT_WAYPOINT_IDS_FOR_RESCHEDULING': (state: S, draftWaypointIDs: string[]) => {
    state.draftWaypointsForRescheduling.draftWaypointIDs = draftWaypointIDs;
  },

  'draftWaypoints/SET_PREVIEWS': (state: S, previews: {
    waypoints: ByID<Waypoint>;
    waypointActions: ByID<WaypointAction>;
  }) => {
    state.previews = previews;
  },
};

const draftWaypointsActions: DraftWaypointsActions = {
  'draftWaypoints/copyDraftWaypoint': async (_, payload) => {
    const draftWaypointID = await copyDraftWaypointRequest(payload);
    return draftWaypointID;
  },

  'draftWaypoints/fetchDraftWaypointDependencies': ({ commit, getters, rootGetters }, payload) => {
    const { bind, draftWaypointID, customContext } = payload;
    return fetchWithRelationships(
      DbRef.DRAFT_WAYPOINTS,
      draftWaypointID,
      getters['draftWaypoints/getDraftWaypointDepsRelationships'],
      rootGetters['app/getDefaultFetchWithRelationshipsCallbacks']?.(commit),
      bind,
      customContext ?? 'draftWaypoints/fetchDraftWaypointDependencies',
    );
  },

  'draftWaypoints/fetchDraftWaypointForRescheduling': async ({ commit, rootGetters, getters }, draftWaypointID) => {
    const response = await fetchWithRelationships(
      DbRef.DRAFT_WAYPOINTS,
      draftWaypointID,
      getters['draftWaypoints/getDraftWaypointDepsRelationships'],
      rootGetters['app/getDefaultFetchWithRelationshipsCallbacks']?.(commit),
      false,
      'draftWaypoints/fetchDraftWaypointForRescheduling',
    );

    const draftWaypoint = response[DbRef.DRAFT_WAYPOINTS]?.[draftWaypointID];
    if (!draftWaypoint) return;

    commit('draftWaypoints/SET_DRAFT_WAYPOINT_IN_OBJ_AND_FOR_RESCHEDULING', draftWaypoint);
  },

  'draftWaypoints/searchAlgolia': async ({ commit, dispatch }, payload) => {
    const algoliaDraftWaypoints = (await searchWithDispatch(dispatch, {
      index: AlgoliaIndexSuffix.DRAFT_WAYPOINTS,
      params: generateDraftWaypointParams(
        payload?.tag,
        payload?.coordBounds,
      ),
    }));

    const draftWaypoints = algoliaDraftWaypoints.map(stripAlgoliaProperties)
      .filter((dw) => !dw.draftOrderID);
    const draftWaypointsObj = arrayToObj(draftWaypoints, 'id');
    commit('draftWaypoints/SET_DRAFT_WAYPOINTS', draftWaypointsObj ?? {});

    draftWaypoints.forEach((draftWaypoint) => {
      dispatch('draftWaypoints/fetchDraftWaypointDependencies', {
        draftWaypointID: draftWaypoint.id,
        bind: true,
        customContext: 'itinerary/searchResults',
      });
    });

    return draftWaypoints;
  },

  'draftWaypoints/fetchDraftWaypointsForRescheduling': async ({ commit, dispatch }) => {
    const draftWaypoints = await dispatch('draftWaypoints/searchAlgolia');
    const draftWaypointIDs = draftWaypoints.map(({ id }) => id);
    commit('draftWaypoints/SET_DRAFT_WAYPOINT_IDS_FOR_RESCHEDULING', draftWaypointIDs);
  },
};

const draftWaypointsGetters = {
  'draftWaypoints/getDraftWaypointByID': (state: S) => (draftWaypointID: string): DraftWaypoint | undefined =>
    state.draftWaypoints[draftWaypointID],

  'draftWaypoints/getDraftWaypointsForRescheduling': (state: S): DraftWaypoint[] =>
    state.draftWaypointsForRescheduling.draftWaypointIDs
      .map((id) => state.draftWaypoints[id])
      .filter(nullishFilter),

  'draftWaypoints/getDraftWaypointDepsRelationships': (_state: S): RequestWithRelationships<DbRef.DRAFT_WAYPOINTS> => {
    const bookingRelationships: RequestWithRelationships<DbRef.BOOKINGS> = {
      patientID: true,
    };
    const ordersRelationships: RequestWithRelationships<DbRef.ORDERS> = {
      prescriberID: true,
      bookings: bookingRelationships,
    };
    const proceduresRelationships: RequestWithRelationships<DbRef.PROCEDURES> = {
      bookingID: bookingRelationships,
      orderID: ordersRelationships,
      placeAccounts: true,
      supplyItems: true,
    };
    const relationships: RequestWithRelationships<DbRef.DRAFT_WAYPOINTS> = {
      procedures: proceduresRelationships,
      supplyTransfers: {
        shipmentIDs: true,
      },
    };

    return relationships;
  },
};

export type DraftWaypointsGetters = typeof draftWaypointsGetters;

export const draftWaypointsModule = {
  state: draftWaypointsState,
  mutations: draftWaypointsMutations,
  actions: draftWaypointsActions,
  getters: draftWaypointsGetters,
};

export type DraftWaypointsModule = typeof draftWaypointsModule;
