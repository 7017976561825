import { isProdDeployment } from '@caresend/ui-components';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';

import { getMatchedMeta, routeNames } from '@/router/model';

export default (_router: VueRouter) => ({
  allowDevAccess: (
    to: Route,
    _from: Route,
    next: NavigationGuardNext<Vue>,
  ) => {
    const { devOnly } = getMatchedMeta(to);
    if (devOnly && isProdDeployment) {
      return next({ name: routeNames.USERS });
    }
    return next();
  },
});
