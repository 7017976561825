import {
  ConfigType,
  SampleType,
  UrineSpecimen,
  initialPCRSupplyProcessing,
  initialTubeSupplyProcessing,
  initialUrineSupplyProcessing,
} from '@caresend/types';
import { generateID } from '@caresend/utils';

import { TableConfig } from '@/store/modules/tests/model';

const isValidPCRForm = (config: TableConfig<ConfigType.PCR>): boolean => {
  const hasNames = !!config.productName && !!config.friendlyName;
  const hasCPTCodes = config.cptCodes.length > 0;
  const hasLab = !!config.placeGroupName && !!config.labCode;
  const hasSamples = config.sampleTypes.length > 0;
  const hasSupply = !!config.supplyID && !!config.supplyName;
  const validTimeInfo = (config.stabilityRTHr > 0)
    && (config.stabilityRefrigeratedHr > 0);

  return hasNames
    && hasCPTCodes
    && hasLab
    && hasSamples
    && hasSupply
    && validTimeInfo;
};

const isValidTubeForm = (config: TableConfig<ConfigType.TUBE>): boolean => {
  const hasNames = !!config.productName && !!config.friendlyName;
  const hasCPTCodes = config.cptCodes.length > 0;
  const hasLab = !!config.placeGroupName && !!config.labCode;
  const hasSupply = !!config.supplyID && !!config.supplyName;
  const validVolumeInfo = (config.volume > 0)
    && (config.minBloodVolume > 0)
    && (config.bloodVolume > 0)
    && (config.minBloodVolume > 0);
  const validTimeInfo = (config.dropOffTimeHr > 0)
    && (config.stabilityRTHr > 0)
    && (config.stabilityRefrigeratedHr > 0);

  return hasNames
    && hasCPTCodes
    && hasLab
    && hasSupply
    && validVolumeInfo
    && validTimeInfo;
};

const isValidUrineForm = (config: TableConfig<ConfigType.URINE>): boolean => {
  const hasNames = !!config.productName && !!config.friendlyName;
  const hasCPTCodes = config.cptCodes.length > 0;
  const hasLab = !!config.placeGroupName && !!config.labCode;
  const hasValidSpecimen = !!config.specimen.length
    && config.specimen.every(
      (specimen) =>
        !!specimen.supplyID
        && specimen.volume > 0
        && specimen.minVolume > 0,
    );
  const validStabilityInfo = (config.refrigeratedStabilityTime > 0)
    && (config.stabilityTime > 0);

  return hasNames
    && hasCPTCodes
    && hasLab
    && hasValidSpecimen
    && validStabilityInfo;
};

export const validateForm = (configType: ConfigType, config: TableConfig): boolean => {
  switch (configType) {
    case ConfigType.PCR:
      return isValidPCRForm(config as TableConfig<ConfigType.PCR>);
    case ConfigType.TUBE:
      return isValidTubeForm(config as TableConfig<ConfigType.TUBE>);
    case ConfigType.URINE:
      return isValidUrineForm(config as TableConfig<ConfigType.URINE>);
  }
};

export const initUrineSpecimen = (): UrineSpecimen => ({
  combinable: true,
  id: generateID(),
  minVolume: 0,
  processing: initialUrineSupplyProcessing,
  supplyID: '',
  volume: 0,
});

export const initPCRConfiguration = (): TableConfig<ConfigType.PCR> => ({
  cptCodes: [],
  friendlyName: '',
  id: generateID(),
  isCombinable: false,
  labCode: '',
  placeGroupID: '',
  placeGroupName: '',
  priceToCaresend: 0,
  productID: '',
  productName: '',
  processing: initialPCRSupplyProcessing,
  sampleTypes: [],
  stabilityRefrigeratedHr: 0,
  stabilityRTHr: 0,
  supplyID: '',
  supplyName: '',
});

export const initTubeConfiguration = (): TableConfig<ConfigType.TUBE> => ({
  bloodVolume: 0,
  cptCodes: [],
  dropOffTimeHr: 0,
  friendlyName: '',
  id: generateID(),
  isCombinable: false,
  labCode: '',
  minBloodVolume: 0,
  minVolume: 0,
  placeGroupID: '',
  placeGroupName: '',
  priceToCaresend: 0,
  processing: initialTubeSupplyProcessing,
  productID: '',
  productName: '',
  sampleType: SampleType.WHOLE_BLOOD,
  stabilityRefrigeratedHr: 0,
  stabilityRTHr: 0,
  standardTurnaround: 0,
  statTurnaround: 0,
  supplyID: '',
  supplyName: '',
  volume: 0,
});

export const initUrineConfiguration = (): TableConfig<ConfigType.URINE> => ({
  combinable: true,
  cptCodes: [],
  friendlyName: '',
  id: generateID(),
  labCode: '',
  placeGroupID: '',
  placeGroupName: '',
  priceToCaresend: 0,
  productID: '',
  productName: '',
  supplyNames: [],
  refrigeratedStabilityTime: 0,
  specimen: [initUrineSpecimen()],
  stabilityTime: 0,
  standardTurnaround: 0,
  statTurnaround: 0,
});

export const initialConfigs = {
  [ConfigType.PCR]: initPCRConfiguration,
  [ConfigType.TUBE]: initTubeConfiguration,
  [ConfigType.URINE]: initUrineConfiguration,
};
