import { ConfigType, Draft, PlaceGroupProductConfig, Product } from '@caresend/types';
import { generateID } from '@caresend/utils';

import type { CustomActionContext } from '@/store/model';

export const initProductData = (): Draft<Product> => ({
  id: generateID(),
});

interface TableConfigInfo {
  placeGroupName: string;
  productID: string;
  productName: string;
}

interface PCRTableConfig extends TableConfigInfo {
  supplyName: string;
}

interface TubeTableConfig extends TableConfigInfo {
  supplyName: string;
}

interface UrineTableConfig extends TableConfigInfo {
  supplyNames: string[];
  combinable: boolean;
}

type TableConfigInfoUnion = PCRTableConfig | TubeTableConfig | UrineTableConfig;

interface TableConfigType extends Record<ConfigType, TableConfigInfoUnion> {
  [ConfigType.PCR]: PCRTableConfig;
  [ConfigType.TUBE]: TubeTableConfig;
  [ConfigType.URINE]: UrineTableConfig;
}

type TableConfigInfoType<T extends ConfigType | undefined = ConfigType | undefined> =
  T extends ConfigType
    ? TableConfigType[T]
    : TableConfigInfoUnion;

export type TableConfig<T extends ConfigType = ConfigType> =
  PlaceGroupProductConfig<T> & TableConfigInfoType<T>;

export interface TestsState {
  productData: Draft<Product>;
  // Not product config type. Product will contain its own config type
  configType: ConfigType;
  config: TableConfig;
  isTestModalOpen: boolean;
}

export type TestsActionContext = CustomActionContext<'tests', TestsState>

export type TestsActions = {
  'tests/getValidatedProduct': (
    ctx: TestsActionContext
  ) => Promise<Product>;
}
