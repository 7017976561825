import { ByID, DbRef, ItineraryEditHistory, ItineraryEditorStatus } from '@caresend/types';
import { getStore, getValueOnce } from '@caresend/ui-components';
import { isNullish } from '@caresend/utils';
import { getDatabase, push, ref } from 'firebase/database';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { defineStore } from 'pinia';
import { nextTick, reactive } from 'vue';

import { cleanAndUpdate } from '@/store/modules/itinerary/helpers';
import { injectNullsDeep } from '@/store/modules/itinerary/utils/injectNulls';

/** Driven by the context of the synchronization (e.g. itinerary) */
type ByContext<T> = ByID<T>;
type ByPath<T> = ByID<T>
export type NextUpdates = ByPath<{
  description: string;
  value: any;
}>
export const useDbSyncStore = defineStore('legacy/dbSync', () => {
  const store = getStore();
  const state = reactive<{
    editHistoryPaths: ByContext<string>;
    loading: boolean;
    nextUpdates: ByContext<NextUpdates>;
  }>({
    editHistoryPaths: {},
    loading: false,
    nextUpdates: {},
  });

  /** Tie the context of a series of executions to a set of updates and edit history state */
  const provideContext = (context: DbRef, id: string) => {
    state.editHistoryPaths = {
      ...state.editHistoryPaths,
      [context]: `${DbRef.HISTORY}/${context}/${id}`,
    };
  };

  const resetNextUpdates = (context?: string) => {
    if (context) {
      state.nextUpdates[context] = {};
    } else {
      state.nextUpdates = {};
    }
  };

  const syncUpdates = async (context: DbRef) => {
    const updatesWithMeta = cloneDeep(state.nextUpdates[context]);
    if (!updatesWithMeta || !Object.keys(updatesWithMeta).length) return;

    const historyPath = state.editHistoryPaths[context];
    if (!historyPath) return;

    resetNextUpdates(context);
    state.loading = true;

    const editTimestamp = Date.now();

    /** TODO: Rename @caresend/types val to `EditHistory` */
    const historyUpdates: ItineraryEditHistory['updates'] = {};

    const nextUpdates = cloneDeep(updatesWithMeta);
    let allUpdates: [string, any][] = [];

    (await Promise.all(
      Object.entries(nextUpdates)
        .map(async ([path, val]) => [path, {
          description: val.description,
          prev: await getValueOnce(path),
        }]),
    )).forEach((nextUpdate) => {
      const [path, val] = nextUpdate as [
          string, {
            prev: any;
            description: string;
          }
        ];
      if (isNullish(path)) return;
      if (isEqual(val.prev, updatesWithMeta[path]?.value)) {
        console.info('Prev and next values are equal, skipping this update: ', path);
        return;
      }
      historyUpdates[encodeURIComponent(path)] = val;
      allUpdates.push([path, updatesWithMeta[path]?.value ?? null]);
    });

    const userID = store.state.auth.user?.id ?? '';
    const newEditHistory: ItineraryEditHistory = {
      editBy: userID,
      editTimestamp,
      updates: historyUpdates,
    };

    allUpdates = [
      ...allUpdates,
      [`${historyPath}/creationHistory/editingUsers/${userID}`, {
        status: ItineraryEditorStatus.ONLINE,
        timestamp: Date.now(),
      }],
    ];

    console.info('Writing updates to itinerary...', allUpdates);
    await cleanAndUpdate(allUpdates);

    const cleanEditHistory = injectNullsDeep(newEditHistory);
    if (cleanEditHistory?.length) {
      console.info('Writing edit history...', cleanEditHistory.updates);
    }
    if (!Object.values(cleanEditHistory ?? {}).length) return;

    /**
     * TODO: Use `generateSortableID` to put the new value at the first position of the list.
     * Edit history component will need to be updated to support this
     */
    const db = getDatabase();
    const dbRef = ref(db, `${historyPath}/creationHistory/editHistory`);
    push(dbRef, cleanEditHistory)
      .catch((error) => console.error('Error updating edit history: ', error));

    state.loading = false;
  };

  const timeouts: Partial<Record<DbRef, ReturnType<typeof setTimeout>>> = {};
  const awaitSubsequentUpdates = (context: DbRef) => {
    if (timeouts[context]) {
      clearTimeout(timeouts[context]);
    }
    timeouts[context] = setTimeout(
      () => nextTick(() => syncUpdates(context)),
      1500,
    );
  };

  const queueNextUpdate = <T>(
    path: string,
    description: string,
    value: T,
    context: DbRef,
  ) => {
    state.nextUpdates[context] = {
      ...state.nextUpdates[context],
      [path]: {
        description,
        value,
      },
    };
    awaitSubsequentUpdates(context);
  };

  return {
    provideContext,
    queueNextUpdate,
    state,
  };
});
