import { EncryptedUser, Role } from '@caresend/types';
import {
  AuthModule,
  AuthState,
  ExtendedCustomModule,
  firebaseAuth,
  initAuthModule,
  toastError,
} from '@caresend/ui-components';

import { identifyAuthenticatedUserSegment } from '@/functions/tracking';
import type { CustomActionContext, CustomStore } from '@/store/model';

type S = AuthState;

export type ExtraAuthActionContext = CustomActionContext<'auth', S>

export type ExtraAuthActions = {
  'auth/bindUserDependencies': (
    context: ExtraAuthActionContext,
  ) => Promise<void>;

  'auth/unbindUserDependencies': (
    context: ExtraAuthActionContext,
  ) => Promise<void>;
}

const extraAuthActions: ExtraAuthActions = {
  'auth/bindUserDependencies': async ({ dispatch, state }) => {
    const { user } = state;
    if (!user) return;

    const { role } = user;
    if (role !== Role.SUPERADMIN && role !== Role.RECRUITER) {
      toastError('Wrong user type for Dashboard.');
      firebaseAuth.signOut();
      return;
    }

    dispatch('offices/bindOffices');
  },

  'auth/unbindUserDependencies': async ({ commit, dispatch }) => {
    dispatch('offices/unbindOffices');

    commit('places/RESET_PLACES');
    commit('places/RESET_PLACE_ACCOUNTS');
    commit('places/RESET_PLACE_GROUPS');
    commit('places/RESET_PLACE_GROUP_TYPES');
    commit('bookings/RESET_BOOKINGS');
  },
};

const authModuleExtension = {
  actions: extraAuthActions,
};

export const authModule: ExtendedCustomModule<
  AuthModule,
  typeof authModuleExtension
> = initAuthModule(authModuleExtension);

export type ExtendedAuthModule = typeof authModule;

export type ExtendedAuthMutations = ExtendedAuthModule['mutations'];
export type ExtendedAuthActions = ExtendedAuthModule['actions'];
export type ExtendedAuthGetters = ExtendedAuthModule['getters'];

export const handleUserBound = async ({ dispatch }: CustomStore, user: EncryptedUser) => {
  await dispatch('auth/bindUserDependencies');

  identifyAuthenticatedUserSegment(user);
};

export const handleUserUnbound = async ({ dispatch }: CustomStore) => {
  dispatch('auth/unbindUserDependencies');
};
