import VueRouter, { NavigationGuardNext, Route } from 'vue-router';

export default (router: VueRouter) => ({
  setLoading: (loading: boolean) => (
    _to: Route,
    _from: Route,
    next?: NavigationGuardNext<Vue>,
  ) => {
    router.app.$store.dispatch('app/updateRouteLoading', loading);
    // Only display loading screen on route change if it's not instant. Typically
    // delays would be caused if js is being downloaded for a code split route.
    if (loading) {
      setTimeout(() => {
        router.app.$store.dispatch('app/showRouteLoading');
      }, 500);
    }
    next?.();
  },
});
