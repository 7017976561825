/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import {
  ByID,
  DbRef,
  ElementTimestamp,
  IdObj,
  Itinerary,
  ItineraryDraft,
  Procedure,
  ProcedureDateInfo,
  SupplyInstructionOptions,
  WaypointAction,
  WaypointActionType,
  WithFriendlyStatus,
  supplyInstructionsRequireProcessing,
} from '@caresend/types';
import { dbUpdate, getStore } from '@caresend/ui-components';
import {
  arrayToObj,
  deduplicateArray,
  initElementTimestamp,
  initWaypointAction,
  nullishFilter,
  objectKeys,
} from '@caresend/utils';
import isEqual from 'lodash.isequal';

import type { RootState } from '@/store/model';
import { TargetOrID, durationsByWaypointActionType } from '@/store/modules/itinerary/model';
import { injectNullsDeep } from '@/store/modules/itinerary/utils/injectNulls';

export const generateWaypointActions = (
  /** Total duration of group of waypoint actions */
  type: WaypointActionType,
  procedures: ByID<Procedure>,
  waypointID: string,
  itineraryID: string,
  totalDuration?: number,
): WaypointAction[] => {
  const actionDuration = totalDuration ?? durationsByWaypointActionType[type];

  const wpaByBookingID: Map<string, WaypointAction> = new Map();
  Object.values(procedures).forEach((procedure) => {
    const { bookingID } = procedure;

    const waypointAction = wpaByBookingID.get(bookingID) ?? initWaypointAction(type, waypointID);
    waypointAction.itineraryID = itineraryID;

    waypointAction.procedures = {
      ...waypointAction.procedures,
      [procedure.id]: initElementTimestamp(procedure.id),
    };
    wpaByBookingID.set(bookingID, waypointAction);
  });

  const waypointActions = Array.from(wpaByBookingID.values());

  const distributedDuration = actionDuration / waypointActions.length;
  waypointActions.forEach((wpa) => {
    wpa.duration = {
      ...wpa.duration,
      actionDuration: distributedDuration,
    };
  });

  return waypointActions;
};

/** @deprecated replaced by itinerary.computed.wpaParentIndex(wpa.id, itinerary) */
export const getWaypointIndexByWaypointAction = (
  wpa: WaypointAction,
  itinerary: Itinerary | ItineraryDraft | null,
): number => {
  const { waypointID } = wpa;
  return itinerary?.waypoints.findIndex(({ id }) => id === waypointID) ?? -1;
};

export const getProceduresDateInfo = (
  procedureIDs: string[] | undefined,
  rootState: RootState,
): ProcedureDateInfo[] | undefined => {
  if (!procedureIDs?.[0]) return;

  const getProcedureDateInfo = (procedureID: string) =>
    rootState.procedures.procedures?.[procedureID]?.dateInfo;

  const firstProcedureID = procedureIDs[0];
  const firstProcedureDateInfo = getProcedureDateInfo(firstProcedureID);
  if (!firstProcedureDateInfo) return;

  const allDateInfosMatch = procedureIDs.every((procedureID: string) =>
    isEqual(getProcedureDateInfo(procedureID), firstProcedureDateInfo),
  );

  if (allDateInfosMatch) return firstProcedureDateInfo;
};

const ASCII_OFFSET = 97;
const ALPHA_COUNT = 26;
export const getWaypointLetter = (waypointIndex: number) => {
  const conversionNumber = waypointIndex;

  const numToChar = (num: number) => String.fromCharCode(num + ASCII_OFFSET);

  const loopCount = Math.floor(conversionNumber / ALPHA_COUNT);
  const mod = conversionNumber % ALPHA_COUNT;
  if (loopCount) {
    return `${numToChar(loopCount - 1)}${numToChar(mod)}`.toUpperCase();
  }
  return `${numToChar(mod)}`.toUpperCase();
};

const getProcedureForProcessing = (
  procedureID: string,
  rootState: RootState,
): WithFriendlyStatus<Procedure> => {
  const procedure = rootState.procedures.procedures?.[procedureID];
  if (!procedure) throw new Error('Procedure not found.');
  if (!procedure.supplyInstructionConfig) {
    throw new Error(
      `Supply instruction config not found on Procedure ${procedure.id}`,
    );
  }
  return procedure;
};

export const getSupplyInstructionThatRequiresProcessing = (
  procedureID: string,
  rootState: RootState,
): SupplyInstructionOptions | undefined => {
  const procedure = getProcedureForProcessing(procedureID, rootState);
  const theSupplyInstruction = supplyInstructionsRequireProcessing.find(
    (option) => Object.values(
      procedure.supplyInstructionConfig?.supplyInstructions ?? {},
    ).some((supplyInstruction) => {
      const requiresProcessing = !!supplyInstruction.instructions?.some(
        (instruction) => {
          const key = Object.keys(instruction)[0];
          const value = Object.values(instruction)[0];
          return key === option && value === true;
        },
      );
      return requiresProcessing;
    }),
  );

  return theSupplyInstruction;
};

export const getUniqueSupplyInstructionOptions = (
  procedureIDs: string[],
  rootState: RootState,
): SupplyInstructionOptions[] => {
  const supplyInstructionOptionsThatRequireProcessing
      = procedureIDs.map((procedureID) =>
        getSupplyInstructionThatRequiresProcessing(procedureID, rootState),
      ).filter(nullishFilter);

  const uniqueSupplyInstructionOptions = deduplicateArray(
    supplyInstructionOptionsThatRequireProcessing,
  );

  return uniqueSupplyInstructionOptions;
};

export const getProceduresBySupplyInstructionOption = (
  procedureIDs: string[],
  option: SupplyInstructionOptions,
  rootState: RootState,
): ByID<ElementTimestamp> => {
  const proceduresWithOption = procedureIDs.filter((procedureID) =>
    getSupplyInstructionThatRequiresProcessing(procedureID, rootState) === option,
  );
  const elementTimestamps: ByID<ElementTimestamp> = arrayToObj(
    proceduresWithOption.map((procedureID) => ({
      id: procedureID,
      timestamp: Date.now(),
    })),
    'id',
  );
  return elementTimestamps;
};

/**
 * Clean up db updates for firebase support by converting arrays to objects
 * and injecting nulls for empty strings, undefined, and empty objects/arrays.
 *
 * Conversion of arrays to objects helps minimize the size of the updates
 * by defining the explicit changes required to be applied to the database
 * each of which can be filtered out from the updates if there was no change.
 */
export const cleanDbUpdates = (
  updates: [string, any][],
): [string, any][] => {
  const cleanUpdates: Record<string, any> = {};
  updates.forEach(([key, value]) => {
    cleanUpdates[key] = injectNullsDeep(value);
  });

  /**
   * Firebase rejects update objects which are provided as more specific than a parent path
   * This loop removes paths that are more specific than a parent path
   */
  objectKeys(cleanUpdates).forEach((path) => {
    const pathSplit = path.split('/');

    let pathSubstr = '';
    pathSplit.forEach((pathPart) => {
      pathSubstr += `${pathPart}`;
      if (pathSubstr === path) return;
      if (pathSubstr in cleanUpdates) {
        delete cleanUpdates[path];
      }
      pathSubstr += '/';
    });
  });
  return Object.entries(cleanUpdates);
};

/**
 * Example input:
 * [
 *  ['waypointActions/0/procedures/0', { id: '123', timestamp: 123 }],
 *  ['waypointActions/0/procedures/1', { id: '456', timestamp: 456 }],
 *  ['waypointActions/0/procedures/1', { timestamp: 789 }],
 * ]
 *
 * Example output:
 * {
 *  waypointActions: {
 *    0: {
 *      procedures: {
 *        0: { id: '123', timestamp: 123 },
 *        1: { id: '456', timestamp: 789 },
 *      },
 *    },
 *  },
 * }
 */
const groupUpdatesByMessyPathDefinitions = (
  uniquePathDefs: [string, any][],
) => {
  const updates: any = {};

  uniquePathDefs.forEach(([key, val]) => {
    /**
     * If a target value was explicitly defined as null,
     * it was indicated that the value should be deleted from the database.
     * This would otherwise be ignored when grouping updates.
     */
    if (val === null) {
      updates[key] = null;
      return;
    }

    const parentRefs = key.split('/');
    const maxIndex = parentRefs.length - 1;

    let currentKeyIndex = 0;
    let currentKey;
    let currentObjChild = updates;

    while (currentKeyIndex < maxIndex) {
      currentKey = parentRefs[currentKeyIndex];
      if (!currentKey) return;

      if (!currentObjChild[currentKey]) currentObjChild[currentKey] = {};
      currentObjChild = currentObjChild[currentKey];

      currentKeyIndex += 1;
    }

    const nestedKey = parentRefs[maxIndex];
    if (!nestedKey) return;

    /** This writes to the allUpdates object since the currentObjChild is a reference to it */
    currentObjChild[nestedKey] = val ?? null;
  });

  return updates as Record<DbRef, ByID<any>>;
};

/**
 * Example input:
 * {
 *  waypointActions: {
 *    0: {
 *      procedures: {
 *        0: { id: '123', timestamp: 123 },
 *        1: { id: '456', timestamp: 456 },
 *      },
 *    },
 *  },
 * }
 *
 * Example output:
 * [
 *  ['waypointActions/0/procedures/0/id', '123'],
 *  ['waypointActions/0/procedures/0/timestamp', 123],
 *  ['waypointActions/0/procedures/1/id', '456'],
 *  ['waypointActions/0/procedures/1/timestamp', 456],
 * ]
 */
export const flattenUpdatesToPathDefinitions = (
  updates: Record<string, any>,
  prefix = '',
): [string, any][] => {
  let result: [string, any][] = [];

  for (const [key, value] of Object.entries(updates)) {
    const currentPath = prefix ? `${prefix}/${key}` : key;
    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      result = result.concat(flattenUpdatesToPathDefinitions(value, currentPath));
    } else {
      // Base case: add the current path and value to result
      result.push([currentPath, value]);
    }
  }

  return result;
};

export const cleanAndUpdate = async (allUpdates: [string, any][]) => {
  const cleanUpdates: [string, any][] = cleanDbUpdates(allUpdates);

  /**
   * This methodology of organizing and grouping updates allows
   * for more transparent updates and sanitary updating. Lets say multiple updates
   * are registered where one node is updated with an object but another node
   * is defined as a child of the original node but with a conflicting value.
   */
  const groupedUpdates: Record<string, any> = groupUpdatesByMessyPathDefinitions(cleanUpdates);
  const flattenedUpdates = flattenUpdatesToPathDefinitions(groupedUpdates);
  const updates = Object.fromEntries(flattenedUpdates);

  console.info('Writing updates to rtdb...', updates);

  try {
    dbUpdate<any>('/', updates as any);
  } catch (error) {
    console.error('Error updating: ', error);
  }
};

/**
 * Helpers that allows store managers to accept either a target object or its ID
 * so hypothetical values can also be plugged into the computational helpers.
 */
export const extractTarget = <T>(
  targetOrID: TargetOrID<T> | undefined,
  getter: (id: string) => T | null,
): T | undefined => {
  if (typeof targetOrID === 'string') return getter(targetOrID) ?? undefined;
  return targetOrID;
};

/**
 * Helper to extract a target object from a getter with priority to an object
 * for cases where the target is known to exist.
 */
export const extractTargetOrPriority = <T extends IdObj>(
  targetOrID: TargetOrID<T> | undefined,
  getter: (id: string) => T | null,
  priority: T,
): T => {
  if (typeof targetOrID === 'string') {
    if (targetOrID === priority.id) return priority;
    return getter(targetOrID) ?? priority;
  }
  return targetOrID ?? priority;
};

/**
 * Helper to create a record of itineraries with the root state's itineraries
 * and optionally a target itinerary. Allows support for itinerary store managers
 * accepting itineraries sourced from contexts outside of the itinerary store.
 */
export const itineraryRecordWithRootState = (
  itinerary?: Itinerary,
): ByID<Itinerary> => ({
  ...getStore().state.itineraries.itineraries,
  ...(!itinerary?.id ? undefined : {
    [itinerary.id]: itinerary,
  }),
});

/**
 * Helper to extract an itinerary from the itinerary store with a fallback to the root state
 */
export const extractItineraryWithRootState = (
  itineraryID: string,
): Itinerary | null => itineraryRecordWithRootState()[itineraryID] ?? null;
