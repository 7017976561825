import { ExtendedCustomModule, OrderFlowModule, initOrderFlowModule } from '@caresend/ui-components';

import type { RootState } from '@/store/model';
import { ExtendedOfficesActions, ExtendedOfficesMutations } from '@/store/modules/offices';
import { ExtendedVariablesGetters } from '@/store/modules/variables';

const orderFlowModuleExtension = {};

export const orderFlowModule: ExtendedCustomModule<
  OrderFlowModule<
    RootState,
    ExtendedOfficesMutations,
    ExtendedOfficesActions,
    ExtendedVariablesGetters
  >,
  typeof orderFlowModuleExtension
> = initOrderFlowModule(orderFlowModuleExtension);

export type ExtendedOrderFlowModule = typeof orderFlowModule;

export type ExtendedOrderFlowModuleMutations = ExtendedOrderFlowModule['mutations'];
export type ExtendedOrderFlowModuleActions = ExtendedOrderFlowModule['actions'];
export type ExtendedOrderFlowModuleGetters = ExtendedOrderFlowModule['getters'];
