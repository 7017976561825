import { ConfigType, Draft, Product } from '@caresend/types';
import { getUnknownErrorMessage, getValidatedProduct } from '@caresend/utils';
import update from 'immutability-helper';

import { initialConfigs, validateForm } from '@/store/modules/tests/helpers';
import { TableConfig, TestsActions, TestsState, initProductData } from '@/store/modules/tests/model';

type S = TestsState;

const testsState: S = {
  configType: ConfigType.TUBE,
  productData: initProductData(),
  config: initialConfigs[ConfigType.TUBE](),
  isTestModalOpen: false,
};

const testsMutations = {
  'tests/RESET_MODULE': (state: S) => {
    state.productData = initProductData();
    state.config = initialConfigs[state.configType]();
    state.isTestModalOpen = false;
  },

  'tests/SET_CONFIG': (state: S, config: TableConfig | undefined) => {
    if (!config) return;
    state.config = config;
  },

  'tests/SET_CONFIG_TYPE_AND_CONFIG': (state: S, configType: ConfigType) => {
    state.configType = configType;
    state.config = initialConfigs[configType]();
  },

  'tests/SET_IS_TEST_MODAL_OPEN': (state: S, isOpen: boolean) => {
    state.isTestModalOpen = isOpen;
  },

  'tests/SET_PRODUCT_NAME': (state: S, name: string) => {
    state.productData = update(state.productData, {
      name: { $set: name },
    });
  },

  'tests/SET_PRODUCT_TASK': (state: S, taskID: string | undefined) => {
    state.productData = update(state.productData, {
      taskID: { $set: taskID },
    });
  },
};

const testsActions: TestsActions = {
  'tests/getValidatedProduct': async ({ state }) => {
    try {
      const draftProduct: Draft<Product> = {
        ...state.productData,
        configType: state.configType,
      };
      return getValidatedProduct(draftProduct);
    } catch (e) {
      const errMessage = getUnknownErrorMessage(e);
      throw Error(errMessage);
    }
  },
};

const testsGetters = {
  'tests/getTestFormTitle': (state: S) =>
    (): string =>
      state.config.productName && state.config.placeGroupName
        ? `${state.config.productName}/${state.config.placeGroupName}`
        : 'Add test',

  'tests/isConfigFormValid': (state: S) =>
    (config: TableConfig): boolean => config
      ? validateForm(state.configType, config)
      : false,

  'tests/isValidProduct': (state: S) =>
    (): boolean => !!state.productData.id
      && !!state.productData.name
      && !!state.productData.taskID,
};

export type TestsGetters = typeof testsGetters;

export const testsModule = {
  state: testsState,
  mutations: testsMutations,
  actions: testsActions,
  getters: testsGetters,
};

export type TestsModule = typeof testsModule;
