import {
  ConfigType,
  DbRef,
  PlaceGroupProductConfig,
  Product,
  Supply,
} from '@caresend/types';
import {
  AnyGetters,
  ExtendedCustomModule,
  VariablesModule,
  VariablesState,
  initVariablesModule,
  toastError,
} from '@caresend/ui-components';
import { configTypeGuards, nullishFilter } from '@caresend/utils';
import update from 'immutability-helper';

import type { CustomActionContext, RootState } from '@/store/model';
import { TableConfig } from '@/store/modules/tests/model';

type S = VariablesState

type ExtraVariablesActionContext = CustomActionContext<'variables', S>

export type ExtraVariablesActions = {
  'variables/getProductCreationUpdates': (
    context: ExtraVariablesActionContext,
    payload: {
      product: Product;
      config: PlaceGroupProductConfig;
    }
  ) => Promise<Record<string, Product>>;
}

const extraVariablesActions: ExtraVariablesActions = {
  'variables/getProductCreationUpdates': async (_, { product, config }) => {
    let updates: Record<string, Product> = {};
    try {
      if (!product) throw Error('Missing Product');
      if (!config) throw Error('Information not valid');

      const newProduct = update(product, {
        placeGroups: { $set: update(product.placeGroups ?? {}, {
          [config.placeGroupID]: { $set: config },
        }) },
      });
      updates = {
        [`${DbRef.PRODUCTS}/${product.id}`]: newProduct,
      };
    } catch (e) {
      toastError(e);
    }
    return updates;
  },
};

const extraVariablesGetters = {
  'variables/getPCRProductPlaceGroupConfigInfo': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): () => TableConfig<ConfigType.PCR>[] =>
    () => {
      const products = extraVariablesGetters['variables/getProductsWithPlaceGroups'](state);
      if (!products || !rootState?.places?.placeGroups) return [];

      const { placeGroups } = rootState.places;
      const productsWithName = products.map((product) => {
        if (!configTypeGuards[ConfigType.PCR](product)) return;

        const placeGroupConfigs = Object.values(product.placeGroups ?? {});
        const productPlaceGroupInfo = placeGroupConfigs.map((productConfig) => {
          const placeGroupName = placeGroups[productConfig.placeGroupID]?.name;
          if (!placeGroupName || !productConfig.supplyID) return;

          const supply = extraVariablesGetters['variables/getSupplyByID'](state)(productConfig.supplyID);
          if (!supply) return;

          const productPlaceGroupConfigInfo: TableConfig<ConfigType.PCR> = {
            productName: product.name,
            productID: product.id,
            placeGroupName,
            supplyName: supply.name,
            ...productConfig,
          };

          return productPlaceGroupConfigInfo;
        });

        return productPlaceGroupInfo.filter(nullishFilter);
      }).filter(nullishFilter);

      return productsWithName.flat();
    },

  'variables/getTubeProductPlaceGroupConfigInfo': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): () => TableConfig<ConfigType.TUBE>[] =>
    () => {
      const products = extraVariablesGetters['variables/getProductsWithPlaceGroups'](state);
      if (!products || !rootState?.places?.placeGroups) return [];

      const { placeGroups } = rootState.places;
      const productsWithName = products.map((product) => {
        if (!configTypeGuards[ConfigType.TUBE](product)) return;

        const placeGroupConfigs = Object.values(product.placeGroups ?? {});
        const productPlaceGroupInfo = placeGroupConfigs.map((productConfig) => {
          const placeGroupName = placeGroups[productConfig.placeGroupID]?.name;
          if (!placeGroupName || !productConfig.supplyID) return;

          const supply = extraVariablesGetters['variables/getSupplyByID'](state)(productConfig.supplyID);
          if (!supply) return;

          const productPlaceGroupConfigInfo: TableConfig<ConfigType.TUBE> = {
            productName: product.name,
            productID: product.id,
            placeGroupName,
            supplyName: supply.name,
            ...productConfig,
          };

          return productPlaceGroupConfigInfo;
        });

        return productPlaceGroupInfo.filter(nullishFilter);
      }).filter(nullishFilter);

      return productsWithName.flat();
    },

  'variables/getUrineProductPlaceGroupConfigInfo': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): () => TableConfig<ConfigType.URINE>[] =>
    () => {
      const products = extraVariablesGetters['variables/getProductsWithPlaceGroups'](state);
      if (!products || !rootState?.places?.placeGroups) return [];

      const { placeGroups } = rootState.places;
      const productsWithName = products.map((product) => {
        if (!configTypeGuards[ConfigType.URINE](product)) return;

        const placeGroupConfigs = Object.values(product.placeGroups ?? {});
        const productPlaceGroupInfo = placeGroupConfigs.map((productConfig) => {
          const placeGroupName = placeGroups[productConfig.placeGroupID]?.name;
          if (!placeGroupName) return;

          const supplyNames = productConfig.specimen.map((specimen) =>
            extraVariablesGetters['variables/getSupplyByID'](state)(specimen.supplyID)?.name,
          ).filter(nullishFilter);

          const combinable = productConfig.specimen.every((specimen) =>
            specimen.combinable === true);

          const productPlaceGroupConfigInfo: TableConfig<ConfigType.URINE> = {
            combinable,
            productName: product.name,
            productID: product.id,
            placeGroupName,
            supplyNames: supplyNames,
            ...productConfig,
          };

          return productPlaceGroupConfigInfo;
        });

        return productPlaceGroupInfo.filter(nullishFilter);
      }).filter(nullishFilter);

      return productsWithName.flat();
    },

  'variables/getProductsWithPlaceGroups': (state: S): Product[] | null => {
    if (!state.variables?.products) return null;
    const productsWithPlaceGroups = Object.values(state.variables.products)
      .filter((product) => !!product.placeGroups);
    return productsWithPlaceGroups;
  },

  'variables/getSupplyByID': (state: S): (supplyID: string) => Supply | undefined =>
    (supplyID) => {
      const supplies = Object.values(state.variables?.supplies ?? {});

      return supplies.find((supply) => supply.id === supplyID);
    },

  'variables/getSupplyByName': (state: S): (supplyName: string | undefined) => Supply | undefined =>
    (supplyName) => Object.values(state.variables?.supplies ?? {})
      .find((curSupply) => curSupply.name === supplyName),
};

const variablesModuleExtension = {
  actions: extraVariablesActions,
  getters: extraVariablesGetters,
};

export const variablesModule: ExtendedCustomModule<
  VariablesModule<RootState>,
  typeof variablesModuleExtension
> = initVariablesModule(variablesModuleExtension);

export type ExtendedVariablesModule = typeof variablesModule;

export type ExtendedVariablesMutations = ExtendedVariablesModule['mutations'];
export type ExtendedVariablesActions = ExtendedVariablesModule['actions'];
export type ExtendedVariablesGetters = ExtendedVariablesModule['getters'];
