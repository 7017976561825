import { AlgoliaIndexSuffix } from '@caresend/types';

import { AlgoliaSearch } from '@/store/modules/algolia/model';

export enum WaypointSupplyListTab {
  CURRENT = 'current',
  PAST = 'past',
}

export type DraftWaypointAlgoliaSearch = AlgoliaSearch
  & { index: AlgoliaIndexSuffix.DRAFT_WAYPOINTS | AlgoliaIndexSuffix.DRAFT_WAYPOINTS_DESCENDING }
