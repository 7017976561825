/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * Record manager to handle record management for different contexts.
 * It's main use case is store state managmeent for entities which are
 * organized by ID.
 *
 * There are three key features which can make the lifecycle of your data
 * management easier:
 * 1. Reactive records
 * 2. Local storage persistence
 * 3. Database synchronization
 * 4. Management and expression of dynamically computed values for each record
 *
 * For initializing a simple record manager which houses any values of
 * type <T extends IdObj>, simply initialize with
 * ```
 * const manager = recordManager()({})
 * ```
 *
 * To persist records to local storage, initialize with
 * ```
 * const manager = recordManager({ persist: true, context: 'some-context' })({})
 * ```
 *
 * To synchronize with a database ref, see the required fields in the `DbSyncMeta`
 * type below.
 *
 * For each recordManager, you can optionally provide a set of computed
 * getters which will be used to compute values for each record. These will be exposed
 * in two ways:
 * 1. As "computed" values within each record
 * 2. As getters which can be used to calculate the same values for records or consider
 *    entities not housed in the given record set.
 *
 * In the first approach, all data is managed in a `reactive` object which watches for
 * changes in the functions provided in the getters.
 *
 * @pumposh
 */
import { ByID, IdObj } from '@caresend/types';
import {
  ChildPath,
  objectMap as _objectMap,
  isNullish,
  nullishFilter,
  objectFilter,
  objectKeys,
} from '@caresend/utils';
import isEqual from 'lodash.isequal';
import { reactive, set, watch } from 'vue';

import { TargetOrID } from '@/store/modules/itinerary/model';
import { ls } from '@/store/utils/persist/persist';
import { dbSync, isDbSyncMeta, setNestedChildOnRecord } from '@/store/utils/recordManager/dbHelpers';
import type {
  DbSyncMeta,
  GenericComputed,
  GenericGetters,
  MaybeWithComputed,
  Meta,
  PersistenceMeta,
  WithComputed,
} from '@/store/utils/recordManager/model';

/** Type assertion is safe but support should be also added to @caresend/utils */
const objectMap = _objectMap as unknown as <T, O>(
  obj: T,
  fn: (v: T[keyof T], key: keyof T) => O,
) => Record<keyof T, O>;

const isItemWithComputed = <T extends IdObj, C>(i: MaybeWithComputed<T, C>): i is WithComputed<T, C> =>
  !isNullish(i?.computed) && 'computed' in i;
const itemWithComputedToRaw = <T extends IdObj, C>(_item: WithComputed<T, C>): T | null => {
  const item: MaybeWithComputed<T, C> = { ..._item };
  delete item?.computed;
  if (Object.values(item).length === 0) return null;
  return item;
};

const DEFAULT_TTL = 1000 * 60 * 60 * 24 * 7; // 1 week

/**
 * The record manager is a utility for managing records in a reactive store.
 * It provides methods for getting, setting, and unsetting records, and
 * getting and setting child records.
 *
 * @param meta - The metadata for database synchronization.
 * @param computedGetters - A set of custom functions which will be used to compute
 * values for each record automatically and cached within the record manager.
 * @param getters - A set of custom functions to compute values for each record.
 * @returns The record manager.
 */
export const recordManager = <T extends IdObj>(meta?: DbSyncMeta<T> | Meta) => {
  type Getters<G> = GenericGetters<G, T>;
  type Computed<G extends Getters<G>> = GenericComputed<G, T>;

  return <C extends object, G extends object>(computedGetters: Getters<C>, getters?: Getters<G>) => {
    /** Initialize the record of items with computed values */
    const recordWithComputeds = meta?.persist
      ? ls.reactive<ByID<WithComputed<T, C>>>(meta.context, {})
      : reactive<ByID<WithComputed<T, C>>>({});

    /** Initialize the record of items without computed values */
    const recordRaw = reactive<ByID<T>>({});

    /**
     * Initialize an object of cache metadata organized by recordID for when utilizing persistence.
     * This will manage clean up records which have passed their expiration date.
     */
    const cacheMeta = meta?.persist
      ? ls.reactive<ByID<PersistenceMeta>>(`${meta.context}[cache-meta]`, {})
      : reactive<ByID<PersistenceMeta>>({});

    /**
     * Clean up the cache meta object on initialization to remove expired records.
     */
    const cleanUpCache = () => {
      Object.entries(cacheMeta).forEach(([id, recordCacheMeta]) => {
        if (!recordCacheMeta) return;
        if (recordCacheMeta.expires < Date.now()) {
          delete recordRaw[id];
          delete recordWithComputeds[id];
          delete cacheMeta[id];
          set(recordRaw, id, null);
          set(recordWithComputeds, id, null);
          set(cacheMeta, id, null);
        }
      });
    };

    cleanUpCache();

    const watches = new Map<string, ReturnType<typeof watch>>();

    /** Watch for changes in the record of items with computed values */
    watch(() => ({ ...recordWithComputeds }), () => {
      objectMap(recordWithComputeds, (item, id) => {
        set(recordRaw, id, itemWithComputedToRaw(item));
      });
    }, { deep: true, immediate: true });

    /** Initialize the computed values for each record */
    const initComputeds = (id: string, item: T | null): Computed<Getters<C>> =>
      objectMap(
        computedGetters,
        (fn) => !isNullish(item) ? fn(id) : null,
      );

    /**
     * Set a record in the store and set with default computed values
     */
    const setRecordItem = (id: string, value: MaybeWithComputed<T, C> | null) => {
      if (value === null) {
        set(recordWithComputeds, id, null);
        set(recordRaw, id, null);
        return;
      }

      const raw = isItemWithComputed(value)
        ? itemWithComputedToRaw(value)
        : value;
      set(recordRaw, id, raw);

      if (raw === null) {
        set(recordWithComputeds, id, null);
        return;
      }

      const withComputed = {
        ...raw,
        computed: initComputeds(id, raw),
      };
      set(recordWithComputeds, id, withComputed);

      if (meta?.persist) {
        set(cacheMeta, id, { expires: Date.now() + (meta.ttl || DEFAULT_TTL) });
      }
    };

    /**
     * If a computed getter is called with no extraneous arguments, return the value
     * last computed for this record.
     */
    const cacheMappedComputedGetters = objectMap(
      computedGetters,
      (fn, name) => (
        _id: TargetOrID<T>,
        ...args: any[]
      ) => {
        if (isNullish(_id)) return fn(_id, ...args);
        const id = typeof _id === 'string' ? _id : _id.id;
        const cached = recordWithComputeds[id]?.computed?.[name];
        if (args.length === 0 && cached) return cached;
        const computed = fn(id, ...args);
        return computed;
      },
    ) as Getters<C>;

    const watchComputedGetters = (id: string) => {
      objectKeys(computedGetters).map((k) => {
        const watchFn = watch(() => computedGetters[k]?.(id), (newVal) => {
          const oldVal = recordWithComputeds[id]?.computed?.[k];
          if (isEqual(newVal, oldVal)) return;
          setRecordItem(id, {
            ...recordWithComputeds[id]!,
            computed: {
              ...recordWithComputeds[id]?.computed,
              [k]: newVal,
            },
          });
        }, { deep: true, immediate: true });
        watches.set(id, watchFn);
      });
    };

    /** Set a record in the store and initialize the computed values if applicable */
    const setAndWatchGetters = (id: string, item: MaybeWithComputed<T, C> | null) => {
      const isCurrentlyWatching = watches.has(id);
      if (Object.keys(computedGetters).length === 0 || isNullish(item) || isCurrentlyWatching) {
        setRecordItem(id, item);
        return;
      }

      /** Initialize the computed values for the record */
      const itemComputed = initComputeds(id, item);

      /**
       * If the computed values are the same as the last computed values,
       * set and skip initialization of computed values.
       */
      if (isEqual(itemComputed, recordWithComputeds[id]?.computed)) {
        setRecordItem(id, item);
        return;
      }

      setRecordItem(id, {
        ...item,
        computed: itemComputed,
      });

      watchComputedGetters(id);
    };

    /** Set up the manager object for external use */
    const manager = {
      forEach: (callback: (item: T) => void) => {
        Object.values(recordWithComputeds).forEach((item) => {
          if (isNullish(item)) return;
          callback(item);
        });
      },

      /**
       * Filter the record set with computed values.
       * @param filter - The filter function to apply to the record set.
       * @returns The filtered record set.
       */
      filter: (filter: (item: WithComputed<T, C>) => boolean) =>
        objectFilter(recordWithComputeds, filter),

      /**
       * Filter the record set raw, i.e. without computed values.
       * @param filter - The filter function to apply to the record set.
       * @returns The filtered record set.
       */
      filterRaw: (filter: (item: T) => boolean) =>
        objectFilter(recordRaw, filter),

      /**
       * Get a record from the store joined with computed values.
       * @param id - The id of the record to get.
       * @returns The record, or null if it does not exist.
       */
      get: (id: string) => {
        const item = recordWithComputeds[id];
        if (isNullish(item)) setRecordItem(id, null);
        return recordWithComputeds[id] as WithComputed<T, C> | null;
      },

      /**
       * Get a record from the store raw, i.e. without computed values.
       * @param id - The id of the record to get, or a filter function to get
       * records that match the filter.
       * @returns The record, or null if it does not exist.
       */
      getRaw: (id: string) => {
        const item = recordRaw[id];
        if (isNullish(item)) setRecordItem(id, null);
        return recordRaw[id] as T | null;
      },

      /**
       * Get a child of a record from the store.
       * @param id - The id of the record to get the child from.
       * @param key - The key of the child record to get.
       * @returns The child record, or null if it does not exist.
       */
      getChildOf: <K extends keyof T>(id: string, key: K): T[K] | undefined =>
        manager.get(id)?.[key],

      getters: {
        ...cacheMappedComputedGetters,
        ...getters,
      } as Getters<G> & Getters<C>,

      /**
       * Get length of the record set.
       * @returns The length of the record set.
       */
      length: () => Object.values(recordWithComputeds).filter(nullishFilter).length,

      /**
       * Log the record set.
       */
      log: () => {
        console.table(objectMap(recordWithComputeds, (r) => r));
      },

      /**
       * Overwrite the entire record set.
       * @param items - The new records to set.
       */
      overwrite: (items: ByID<T>) => {
        manager.reset();
        manager.update(items);
      },

      /**
       * Set a record in the store.
       * @param item - The record to set.
       * @param writeToDb - Whether to write the record to the database.
       */
      set: (_item: T, writeToDb: string | boolean = false) => {
        /** If at runtime, the item may have computed values which we want to ignore */
        const item = isItemWithComputed(_item) ? itemWithComputedToRaw(_item) : _item;

        if (isNullish(item)) return;

        setAndWatchGetters(item.id, item);

        if (!writeToDb || !isDbSyncMeta(meta)) return;
        dbSync(
          item.id,
          item,
          meta.pathGetter(item.id),
          meta.label,
          meta.context,
          writeToDb,
        );
      },

      /**
       * Set a child item of a record in the store.
       * @param id - The id of the record to set the child on.
       * @param key - The key of the child record to set.
       * @param value - The value of the child record to set.
       * @param writeToDb - Whether to write the record to the database.
       */
      setChildOf: <K extends ChildPath<T>>(
        id: string,
        key: K,
        value: K extends keyof T ? T[K] : any,
        writeToDb: string | boolean = false,
      ) => {
        /** Determine if the key can directly be indexed on the record */
        const keyIsDirectKey = (k: K | keyof T): k is keyof T =>
          !String(k)?.includes('/');

        if (!recordWithComputeds[id]) return;

        const explicit = itemWithComputedToRaw<T, C>(recordWithComputeds[id]!);

        if (keyIsDirectKey(key) && explicit) {
          explicit[key] = value;
          manager.set(explicit);
        } else if (explicit) {
          const updated = setNestedChildOnRecord(explicit, key, value);
          manager.set(updated);
        }

        if (!writeToDb || !isDbSyncMeta(meta)) return;

        const path = meta.pathGetter(id, key);
        dbSync(
          id,
          value,
          path,
          meta.label,
          meta.context,
          writeToDb,
        );
      },

      /**
       * Unset a record from the store.
       * @param id - The id of the record to unset.
       * @param writeToDb - Whether to write the record to the database.
       */
      unset: (id: string, writeToDb: string | boolean = false) => {
        set(recordWithComputeds, id, null);
        delete recordWithComputeds[id];

        if (!writeToDb) return;
        if (!isDbSyncMeta(meta)) return;
        if (!meta?.allowUnset) {
          // eslint-disable-next-line no-console
          console.warn('[RecordManager]:unset: `allowUnset` is false, record will not be deleted from db');
          return;
        }
        dbSync(
          id,
          null,
          meta.pathGetter(id),
          meta.label,
          meta.context,
          writeToDb,
        );
      },

      /**
       * Reset the entire record set.
       */
      reset: () => {
        Object.keys(recordWithComputeds).forEach((id) => {
          manager.unset(id);
        });

        if (meta?.persist) {
          localStorage.removeItem(meta.context);
        }
      },

      resetComputed: () => {
        watches.forEach((watchFn) => watchFn());
        Object.keys(recordWithComputeds).forEach((id) => {
          watchComputedGetters(id);
        });
      },

      /**
       * Update the record set with new records.
       * @param items - The new records to update.
       */
      update: (items: ByID<T>) => {
        Object.values(items).forEach((item) => {
          manager.set(item);
        });
      },
    };

    return manager;
  };
};
