import {
  ByID,
  DbRef,
  ElementTimestamp,
  OfficeType,
  Place,
  PlaceGroup,
  PlaceGroupType,
  PlaceGroupTypeKind,
} from '@caresend/types';
import {
  ExtendedCustomModule,
  PlacesModule,
  PlacesState,
  dbGroupSet,
  firebaseBind,
  firebaseUnbind,
  initPlacesModule,
} from '@caresend/ui-components';
import {
  getPlaceGroupPath,
  getPlacePath,
  getValidatedPlaceGroupType,
  initElementTimestamp,
  nullishFilter,
} from '@caresend/utils';
import update from 'immutability-helper';
import cloneDeep from 'lodash.clonedeep';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { CustomActionContext } from '@/store/model';

type S = PlacesState;

type ExtraPlacesActionContext = CustomActionContext<'places', S>

export const usePlacesStore = defineStore('places', () => {
  const places = ref<ByID<Place>>({});
  const placeGroupTypes = ref<ByID<PlaceGroupType>>({});
  const placeGroups = ref<ByID<PlaceGroup>>({});

  const sortedDescPlaceGroupTypes = computed(() =>
    Object.values(placeGroupTypes.value ?? {})
      .filter(nullishFilter)
      .sort((a, b) => b.timestamp - a.timestamp),
  );

  const bindAllPlaceData = async () => {
    await Promise.all([
      firebaseBind<ByID<Place>>(DbRef.PLACES, (dbPlaces) => {
        if (!dbPlaces) return;
        places.value = dbPlaces;
      }),
      firebaseBind<ByID<PlaceGroup>>(DbRef.PLACE_GROUPS, (dbPlaceGroups) => {
        if (!dbPlaceGroups) return;
        placeGroups.value = dbPlaceGroups;
      }),
      firebaseBind<ByID<PlaceGroupType>>(DbRef.PLACE_GROUP_TYPES, (dbPlaceGroupTypes) => {
        if (!dbPlaceGroupTypes) return;
        placeGroupTypes.value = dbPlaceGroupTypes;
      }),
    ]);
  };

  const getPlaceGroupTypeKind = (placeGroupTypeID: string): PlaceGroupTypeKind =>
    placeGroupTypes.value?.[placeGroupTypeID]?.type ?? PlaceGroupTypeKind.LAB;

  const getOfficeTypeFromPlaceGroupTypeKind = (placeGroupTypeID: string): OfficeType => {
    const placeGroupTypeKind = getPlaceGroupTypeKind(placeGroupTypeID);
    switch (placeGroupTypeKind) {
      case PlaceGroupTypeKind.STAFFING:
        return OfficeType.STAFFING;
      case PlaceGroupTypeKind.LAB:
      case PlaceGroupTypeKind.FACILITY:
        return OfficeType.LAB;
    }
  };

  const getIsFacility = (placeGroupTypeID: string): boolean =>
    getPlaceGroupTypeKind(placeGroupTypeID) === PlaceGroupTypeKind.FACILITY;

  const setPlaceGroupType = (placeGroupType: PlaceGroupType) => {
    placeGroupTypes.value = update(placeGroupTypes.value, {
      [placeGroupType.id]: { $set: placeGroupType },
    });
  };

  const savePlaceGroupType = async (placeGroupType: PlaceGroupType) => {
    const validPlaceGroupType = getValidatedPlaceGroupType(placeGroupType);

    const updates: Record<string, PlaceGroupType> = {
      [`${DbRef.PLACE_GROUP_TYPES}/${validPlaceGroupType.id}`]: validPlaceGroupType,
    };

    await dbGroupSet<PlaceGroupType>(updates);
    setPlaceGroupType(validPlaceGroupType);
  };

  const setPlaceGroup = (placeGroup: PlaceGroup) => {
    placeGroups.value = update(placeGroups.value, {
      [placeGroup.id]: { $set: placeGroup },
    });
  };

  const setPlaces = async (inputPlaceGroup: PlaceGroup, inputPlaces?: Place[]) => {
    const placeGroup = inputPlaceGroup;

    if (!placeGroup.id || !placeGroup.placeGroupTypeID) return;

    const updates: Record<string, Place | PlaceGroup | ElementTimestamp> = {};

    inputPlaces?.forEach((inputPlace: Place) => {
      const place = cloneDeep(inputPlace);
      if (!place.id) return;

      const faxNumbers = place.faxNumbers?.map((faxNumber) => {
        if (!faxNumber.number) return undefined;
        return faxNumber;
      }).filter(nullishFilter) ?? [];

      if (!faxNumbers.length) delete place.faxNumbers;
      else place.faxNumbers = faxNumbers;

      updates[getPlacePath(place.id)] = {
        ...place,
        creationTimestamp: Date.now(),
      };

      placeGroup.places = {
        ...placeGroup.places,
        [place.id]: initElementTimestamp(place.id),
      };
    });

    updates[getPlaceGroupPath(placeGroup.id)] = placeGroup;

    if (placeGroup.placeGroupTypeID) {
      const placeGroupTypePath
        = `${DbRef.PLACE_GROUP_TYPES}/${placeGroup.placeGroupTypeID}/placeGroups/${placeGroup.id}`;
      updates[placeGroupTypePath] = initElementTimestamp(placeGroup.id);
    }

    await dbGroupSet<Place | PlaceGroup | ElementTimestamp>(updates);
    placeGroups.value = update(placeGroups.value, {
      [placeGroup.id]: { $set: placeGroup },
    });
    inputPlaces?.forEach((inputPlace: Place) => {
      places.value = update(places.value, {
        [inputPlace.id]: { $set: inputPlace },
      });
    });
  };

  /**
   * Computed getters that take a parameter are not reactive
   * Method is used instead.
   */
  const sortPlaceGroupTypePlaceGroupsDesc = (placeGroupTypeID: string) =>
    Object.values(placeGroupTypes.value?.[placeGroupTypeID]?.placeGroups ?? {})
      .map((placeGroup) => placeGroups.value?.[placeGroup.id])
      .filter(nullishFilter)
      .sort((a, b) => b.timestamp - a.timestamp);

  const unbindAllPlaceData = () => {
    firebaseUnbind(DbRef.PLACES);
    firebaseUnbind(DbRef.PLACE_GROUPS);
    firebaseUnbind(DbRef.PLACE_GROUP_TYPES);
  };

  return {
    bindAllPlaceData,
    getIsFacility,
    getOfficeTypeFromPlaceGroupTypeKind,
    getPlaceGroupTypeKind,
    placeGroupTypes,
    placeGroups,
    places,
    savePlaceGroupType,
    setPlaceGroup,
    setPlaceGroupType,
    setPlaces,
    sortPlaceGroupTypePlaceGroupsDesc,
    sortedDescPlaceGroupTypes,
    unbindAllPlaceData,
  };
});

export type ExtraPlacesActions = {
  'places/bindAllPlaceData': (
    context: ExtraPlacesActionContext,
  ) => void;

  'places/unbindAllPlaceData': (
    context: ExtraPlacesActionContext,
  ) => void;
}

const extraPlacesActions: ExtraPlacesActions = {
  'places/bindAllPlaceData': ({ commit }) => {
    const placesPath = `${DbRef.PLACES}`;
    firebaseBind<ByID<Place>>(placesPath, (places) => {
      if (!places) return;
      commit('places/SET_PLACES', places);
    });

    const placeGroupsPath = `${DbRef.PLACE_GROUPS}`;
    firebaseBind<ByID<PlaceGroup>>(placeGroupsPath, (placeGroups) => {
      if (!placeGroups) return;
      commit('places/SET_PLACE_GROUPS', placeGroups);
    });

    const placeGroupTypesPath = `${DbRef.PLACE_GROUP_TYPES}`;
    firebaseBind<ByID<PlaceGroupType>>(placeGroupTypesPath, (placeGroupTypes) => {
      if (!placeGroupTypes) return;
      commit('places/SET_PLACE_GROUP_TYPES', placeGroupTypes);
    });
  },

  'places/unbindAllPlaceData': () => {
    const placesPath = `${DbRef.PLACES}`;
    firebaseUnbind(placesPath);

    const placeGroupsPath = `${DbRef.PLACE_GROUPS}`;
    firebaseUnbind(placeGroupsPath);

    const placeGroupTypesPath = `${DbRef.PLACE_GROUP_TYPES}`;
    firebaseUnbind(placeGroupTypesPath);
  },
};

export const placesModuleExtension = {
  actions: extraPlacesActions,
};

export const placesModule: ExtendedCustomModule<
  PlacesModule,
  typeof placesModuleExtension
> = initPlacesModule(placesModuleExtension);

export type ExtendedPlacesModule = typeof placesModule;

export type ExtendedPlacesMutations = ExtendedPlacesModule['mutations'];
export type ExtendedPlacesActions = ExtendedPlacesModule['actions'];
export type ExtendedPlacesGetters = ExtendedPlacesModule['getters'];
