import { ByID, DbRef, Office } from '@caresend/types';
import {
  ExtendedCustomModule,
  OfficesModule,
  OfficesState,
  firebaseBind,
  firebaseUnbind,
  initOfficesModule,
} from '@caresend/ui-components';

import type { CustomActionContext } from '@/store/model';
import { ExtendedUsersMutations } from '@/store/modules/users';

type S = OfficesState;

type ExtraOfficesActionContext = CustomActionContext<'offices', S>;

export type ExtraOfficesActions = {
  'offices/bindOffices': (
    context: ExtraOfficesActionContext,
  ) => void;

  'offices/unbindOffices': (
    context: ExtraOfficesActionContext,
  ) => void;
}

const extraOfficesActions: ExtraOfficesActions = {
  'offices/bindOffices': ({ commit }) => {
    const path = `${DbRef.OFFICES}`;
    firebaseBind<ByID<Office>>(path, (offices) => {
      if (offices) commit('offices/SET_OFFICES', offices);
    });
  },

  'offices/unbindOffices': ({ commit }) => {
    const path = `${DbRef.OFFICES}`;
    firebaseUnbind(path);
    commit('offices/RESET_OFFICES');
  },
};

const officesModuleExtension = {
  actions: extraOfficesActions,
};

export const officesModule: ExtendedCustomModule<
OfficesModule<ExtendedUsersMutations>,
typeof officesModuleExtension
> = initOfficesModule(officesModuleExtension);

export type ExtendedOfficesModule = typeof officesModule;

export type ExtendedOfficesMutations = ExtendedOfficesModule['mutations'];
export type ExtendedOfficesActions = ExtendedOfficesModule['actions'];
export type ExtendedOfficesGetters = ExtendedOfficesModule['getters'];
