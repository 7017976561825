import { InterviewCriterion } from '@caresend/types';
import { capitalize } from '@caresend/utils';

export const isOdd = (num: number) => num % 2 === 1;

export const deleteSpaceString = (string: string) => string.replace(/\s+/g, '');

export const nameWithVersionNumber = (name: string) =>
  `${name}-${process.env.VUE_APP_VERSION}-${process.env.VUE_APP_ENV}`;

export function parseInterviewCriteria(
  generalCriteria: InterviewCriterion[],
  nurseScores?: InterviewCriterion[],
): InterviewCriterion[] {
  const result: InterviewCriterion[] = [];
  generalCriteria.forEach((criterion: InterviewCriterion) => {
    const nurseCriteria = nurseScores?.find((i: InterviewCriterion) => i.id === criterion.id);
    if (nurseCriteria) {
      result.push(nurseCriteria);
    } else {
      const newCriterion: InterviewCriterion = {
        id: criterion.id,
        name: criterion.name,
        value: null,
      };
      result.push(newCriterion);
    }
  });
  return result;
}

export function convertTimeTo12H(time: number) {
  const timeString = time.toString();
  const hoursString = timeString.substring(0, timeString.length - 2);
  let amPm;
  const hours12H = (() => {
    const hours = parseInt(hoursString, 10);
    amPm = hours < 12 || hours === 24 ? 'am' : 'pm';
    return hours % 12 || 12;
  })();
  const minutesString = timeString.substring(timeString.length - 2, timeString.length);
  return `${hours12H}${minutesString === '00' ? '' : `:${minutesString}`} ${amPm}`;
}

export function displayMinutes(inputMinutes: number) {
  const minutes = Math.round(inputMinutes);
  if (minutes < 60) return `${minutes} min`;
  const mins = Math.floor(minutes % 60);
  const hours = Math.floor(minutes / 60);
  const hrWord = hours > 1 ? 'hours' : 'hour';
  if (mins === 0) return `${hours} ${hrWord}`;
  if (mins === 30) return `${hours}.5 hours`;
  return `${hours} ${hrWord} ${mins} min`;
}

export const toUpperCase = (str: string): string => str.toUpperCase();

export const formatKebabCase = (str: string): string => str.split('-').map(capitalize).join(' ');

export const formatCamelCase = (str: string): string => capitalize(str.replace(/([a-z0-9])([A-Z])/g, '$1 $2'));

export const buildBulletSeparatedString = (items: Array<string | null | undefined>): string => {
  const filteredItems = items.filter((item): item is string => !!item);
  return filteredItems.reduce((previousVal, item, index) => {
    const bulletPrefix = index !== 0 ? ' • ' : '';
    return `${previousVal}${bulletPrefix}${item}`;
  }, '');
};
