import { DbRef, Itinerary, ItineraryStatus } from '@caresend/types';

import type { RootState } from '@/store/model';
import { cleanAndUpdate } from '@/store/modules/itinerary/helpers';

export const deleteDraftItinerary
  = async (itinerary: Itinerary, rootState: RootState): Promise<Record<string, null>> => {
    if (itinerary.status !== ItineraryStatus.DRAFT) return {};

    const updates: Record<string, null> = {};
    updates[`${DbRef.ITINERARIES}/${itinerary.id}`] = null;
    itinerary.waypoints.forEach(({ id }) => {
      const waypoint = rootState.itineraries.waypoints?.[id];
      if (!waypoint) return;

      updates[`${DbRef.WAYPOINTS}/${waypoint.id}`] = null;
      waypoint.waypointActions?.forEach(({ id: wpaID }) => {
        const wpa = rootState.itineraries.waypointActions?.[wpaID];
        if (!wpa) return;

        updates[`${DbRef.WAYPOINT_ACTIONS}/${wpa.id}`] = null;
        Object.keys(wpa.shipments ?? {}).forEach((shipmentID) => {
          const shipment = rootState.supplies.shipments?.[shipmentID];
          if (!shipment) return;

          updates[`${DbRef.SHIPMENTS}/${shipment.id}`] = null;
        });
      });
    });

    await cleanAndUpdate(Object.entries(updates));

    return updates;
  };
