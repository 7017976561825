import { DbRef, IdObj } from '@caresend/types';

import { useDbSyncStore } from '@/store/modules/itinerary/dbSyncStore';
import type { DbSyncMeta, Meta } from '@/store/utils/recordManager/model';

export const isDbSyncMeta = <T extends IdObj>(
  meta?: Meta | DbSyncMeta<T>,
): meta is DbSyncMeta<T> => !!meta && Object.hasOwnProperty.call(meta, 'pathGetter');

export const isDbRef = (context: DbRef | string): context is DbRef =>
  Object.values(DbRef).includes(context as DbRef);

/**
 * Queue a record to be synced with the database.
 *
 * @param id - The id of the record.
 * @param item - The item to sync to the database.
 * @param path - The path to the value to set.
 * @param entityLabel - The label of the entity.
 * @param context - The context to sync the record to.
 * @param writeLabel - The label of the write.
 */
export const dbSync = <T>(
  id: string,
  item: T | null,
  path: string,
  entityLabel: string,
  context: DbRef | string,
  writeLabel: string | boolean = false,
) => {
  let label: string;

  if (!isDbRef(context)) return;

  if (typeof writeLabel === 'string') {
    label = writeLabel;
  } else {
    label = `set ${entityLabel} ${id.substring(0, 5)}...`;
  }

  useDbSyncStore().queueNextUpdate(
    path,
    label,
    item,
    context,
  );
};

/**
 * Set a nested value on a record.
 *
 * @param obj - The object to set the value on.
 * @param path - The path to the value to set separated by `/`.
 * @param value - The value to set the nested key to.
 * @returns The updated object.
 */
export const setNestedChildOnRecord = <T extends object>(
  obj: T,
  path: string,
  value: any,
): T => {
  const keys = path.split('/');
  const key = keys[0];

  if (keys.length === 1) {
    return { ...obj, [key as keyof T]: value };
  }

  return {
    ...obj,
    [key as keyof T]: setNestedChildOnRecord(
      (obj[key as keyof T] as unknown as object) || {},
      keys.slice(1).join('/'),
      value,
    ),
  };
};
