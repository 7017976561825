import { deduplicateArray } from '@caresend/utils';

import type { UserIDsObj } from '@/store/model';

/**
 * For use in store actions that call a series of promises, where some or all
 * promises return `UserIDsObj`.
 */
export const getUserIDsFromPromises = (
  resolvedPromises: (UserIDsObj | void)[],
): string[] => {
  const userIDsFromPromises = resolvedPromises
    .filter((resolved): resolved is UserIDsObj => !!resolved?.userIDs)
    .map((userIDsObj) => userIDsObj.userIDs ?? [])
    .flat();

  return deduplicateArray(userIDsFromPromises);
};
