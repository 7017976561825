import VueRouter from 'vue-router';

import packageJSON from '@/../package.json';

export default (router: VueRouter) => ({
  checkVersion: () => {
    const storedVersionNumber = localStorage.getItem('versionNumber');
    const currentVersionNunmber = packageJSON.version;
    if (storedVersionNumber !== currentVersionNunmber) {
      localStorage.setItem('versionNumber', currentVersionNunmber);
      window.location.reload();
    }
  },
  handleChunkError: (error: Error) => {
    const pattern = 'Loading chunk';
    const isChunkLoadFailed = error.message.includes(pattern);

    const targetPath = router.currentRoute.fullPath;
    if (isChunkLoadFailed) {
      router.replace(targetPath);
    }
  },
});
