import { Hit } from '@algolia/client-search';
import { AlgoliaIndexType } from '@caresend/types';

import type { CustomStore } from '@/store/model';
import { AlgoliaSearch } from '@/store/modules/algolia/model';

/**
 * Wrapper function for calls to 'algolia/search' action with return type
 * based on passed index, suitable for calling from within a vuex module.
 *
 * Note: This function is written separately as our custom vuex types do not
 * support using generics for return types.
 */
export const searchWithDispatch = async <
  T extends AlgoliaSearch,
  R extends AlgoliaIndexType[T['index']]
>(dispatch: CustomStore['dispatch'], { index, params, query }: T): Promise<Hit<R>[]> =>
  dispatch('algolia/search', { index, params, query }) as Promise<Hit<R>[]>;
