import {
  ByID,
  CareSendEncryptionError,
  DbRef,
  ElementTimestamp,
  EncryptedOrder,
  EncryptedProcedure,
  EncryptedUser,
  Order,
  Procedure,
  Product,
  ProductInfo,
  StatusColor,
  Task,
  WaypointActionType,
} from '@caresend/types';
import {
  AnyGetters,
  ExtendedCustomModule,
  Getters,
  ProceduresModule,
  ProceduresState,
  VariablesGetters,
  firebaseBind,
  firebaseUnbind,
  getValueOnce,
  initProceduresModule,
  unwrapMaybeEncryptedData,
} from '@caresend/ui-components';
import {
  arrayToObj,
  deduplicateArray,
  getTimeInstructionDetailsString,
  nullishFilter,
  procedureDateInfo,
} from '@caresend/utils';
import dayjs from 'dayjs';

import { formatDate } from '@/functions/date';
import { buildBulletSeparatedString } from '@/functions/methods';
import { getUserIDsFromPromises } from '@/store/helpers';
import type { CustomActionContext, RootState, UserIDsObj, WithSkipUserFetches } from '@/store/model';
import { ExtendedOrdersGetters } from '@/store/modules/orders';
import { ExtendedUsersGetters } from '@/store/modules/users';

export interface ProcedureFriendlyInfo {
  color?: string;
  dateInfo?: string;
  friendlyStatus?: string;
  timeInfo?: string;
}

export interface ProcedureDateTimeInfo {
  creation?: string;
  requested: string[] | undefined;
}

export type BindProcedureParams = WithSkipUserFetches<{
  procedureID: string;
  bindWaypointActions?: boolean;
  fetchNurse?: boolean;
}>

type S = ProceduresState;

type ExtraProceduresActionContext = CustomActionContext<'procedures', S>
export type ExtraProceduresActions = {
  'procedures/bindProcedure': (
    context: ExtraProceduresActionContext,
    bindProcedureParams: BindProcedureParams,
  ) => Promise<UserIDsObj>;

  'procedures/fetchProcedure': (
    context: ExtraProceduresActionContext,
    procedureID: string,
  ) => Promise<EncryptedProcedure | null>;

  'procedures/unbindProcedure': (
    context: ExtraProceduresActionContext,
    procedureID: string,
  ) => Promise<void>;
}

const extraProceduresActions: ExtraProceduresActions = {
  'procedures/bindProcedure': async (
    { commit, dispatch },
    { procedureID, bindWaypointActions, fetchNurse, skipUserFetches },
  ) => {
    const userIDs: string[] = [];

    const path = `${DbRef.PROCEDURES}/${procedureID}`;
    await firebaseBind<EncryptedProcedure>(path, async (encryptedProcedure) => {
      if (!encryptedProcedure) return firebaseUnbind(path);
      const { contact, bookingID } = encryptedProcedure;
      const procedure: Procedure = {
        ...encryptedProcedure,
        contact: undefined,
        procedureCanceled: undefined,
        results: undefined,
      };
      const promises: Promise<UserIDsObj | void>[] = [];

      promises.push(dispatch(
        'bookings/fetchBookingForViewing',
        { bookingID, skipUserFetches: true },
      ));

      if (procedure.specimenBag) {
        promises.push(dispatch(
          'supplies/fetchSupplyItemByID', procedure.specimenBag.id,
        ));
      }

      if (procedure.placeAccounts) {
        promises.push(dispatch(
          'places/fetchPlaceAccountsAndGroups',
          { placeAccountIDs: Object.keys(procedure.placeAccounts) },
        ));
      }

      if (bindWaypointActions) {
        const waypointActionPromises = Object.keys(procedure.waypointActions ?? {})
          .map((waypointActionID) =>
            dispatch('itineraries/bindWaypointAction', {
              waypointActionID,
              bindItinerary: true,
              bindWaypoint: true,
              skipUserFetches: true,
            }),
          );

        promises.push(...waypointActionPromises);
      }

      const resolvedPromises = await Promise.all(promises);

      // Decryption

      if (contact) {
        const decryptedContact = await unwrapMaybeEncryptedData(contact);
        if (decryptedContact !== CareSendEncryptionError.PERMISSION_DENIED) {
          procedure.contact = decryptedContact;
          if (decryptedContact.userID) {
            userIDs.push(decryptedContact.userID);
          }
        }
      }

      if (encryptedProcedure.procedureCanceled) {
        const decryptedCanceled = await unwrapMaybeEncryptedData(encryptedProcedure.procedureCanceled.canceledBy);
        procedure.procedureCanceled = {
          ...encryptedProcedure.procedureCanceled,
          canceledBy: decryptedCanceled,
        };
      }

      if (encryptedProcedure.results) {
        const resultsArray = Object.values(encryptedProcedure.results);
        const decryptedResultsPromises = resultsArray.map(async (result) => ({
          ...result,
          result: await unwrapMaybeEncryptedData(result.result),
        }));
        const decryptedResultsArray = await Promise.all(decryptedResultsPromises);
        const decryptedResults = arrayToObj(decryptedResultsArray, 'productID');
        procedure.results = decryptedResults;
      }

      commit('procedures/SET_PROCEDURE', procedure);

      if (fetchNurse && procedure.nurseID) {
        userIDs.push(procedure.nurseID);
      }

      userIDs.push(...getUserIDsFromPromises(resolvedPromises));
    });

    const uniqueUserIDs = deduplicateArray(userIDs);
    if (!skipUserFetches) await dispatch('users/fetchUsers', uniqueUserIDs);

    return { userIDs: uniqueUserIDs };
  },

  'procedures/fetchProcedure': async (_ctx, procedureID: string) => {
    const encryptedProcedure = await getValueOnce<EncryptedProcedure>(`${DbRef.PROCEDURES}/${procedureID}`);
    return encryptedProcedure;
  },

  'procedures/unbindProcedure': async (_context, procedureID) => {
    const path = `${DbRef.PROCEDURES}/${procedureID}`;
    await firebaseUnbind(path);
  },
};

const extraProceduresGetters = {
  'procedures/getProcedureBooking': (state: S, _getters: AnyGetters, rootState: RootState) => (
    procedureID: string,
  ) => {
    const bookingID = state.procedures[procedureID]?.bookingID;
    if (!bookingID) return;

    return rootState.bookings.bookings[bookingID];
  },

  'procedures/getProcedurePatientID': (state: S, getters: AnyGetters, rootState: RootState) => (
    procedureID: string,
  ): string | undefined => {
    const booking = extraProceduresGetters['procedures/getProcedureBooking'](state, getters, rootState)(procedureID);
    return booking?.patientID;
  },

  'procedures/getProcedureOrderID': (state: S, getters: AnyGetters) => (procedureID: string): string | undefined => {
    const procedure = state.procedures[procedureID];
    const bookingID = procedure?.bookingID;
    if (!bookingID) return;
    const orderID = getters['bookings/getBookingOrderID']?.(bookingID);
    return orderID;
  },

  'procedures/getProcedureOrder': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ) => (procedureID: string): Order | EncryptedOrder| undefined => {
    const orderID = extraProceduresGetters['procedures/getProcedureOrderID'](state, getters)(procedureID);
    if (!orderID) return;
    const order = rootState.orders.orders?.[orderID];
    return order;
  },

  'procedures/getProcedureOfficeID': (state: S, getters: AnyGetters, rootState: RootState) => (procedureID: string) => {
    const orderID = extraProceduresGetters['procedures/getProcedureOrderID'](state, getters)(procedureID);
    if (!orderID) return;
    const officeID = rootState.orders.orders?.[orderID]?.officeID;
    return officeID;
  },

  'procedures/getProcedurePrescriberID': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ) => (procedureID: string) => {
    const orderID = extraProceduresGetters['procedures/getProcedureOrderID'](state, getters)(procedureID);
    if (!orderID) return;
    const prescriberID = rootState.orders.orders?.[orderID]?.prescriberID;
    return prescriberID;
  },

  'procedures/getProcedurePatientWaypointAction': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (procedureID: string) => {
    const procedure = state.procedures[procedureID];
    if (!procedure) return;
    const waypointActionRefs = procedure.waypointActions;
    const waypointActions = Object.values(waypointActionRefs ?? {})
      .map(({ id }) => rootState.itineraries.waypointActions?.[id])
      .filter(nullishFilter);
    return waypointActions.find((wpa) => wpa.type === WaypointActionType.PATIENT_ACTION);
  },

  'procedures/getProcedureSupplyConfigString': (
    state: S,
    _getters: AnyGetters,
    _rootState: RootState,
    rootGetters: Getters<VariablesGetters>,
  ) => (
    procedureID: string,
  ) => {
    const procedure = state.procedures[procedureID];
    if (!procedure?.supplyInstructionConfig) return;

    const supplyGetter = rootGetters['variables/getSupplyByID'];

    const supplies = Object.values(procedure.supplyInstructionConfig.supplyInstructions ?? {})
      .map((instruction) => ({
        quantity: instruction.quantity,
        name: (supplyGetter(instruction.supplyID)?.name ?? '').replace('CareSend ', ''),
      }))
      .filter(({ name }) => !!name)
      .map(({ quantity, name }) => `${quantity} ${name}`);

    return buildBulletSeparatedString(supplies) || 'No supplies';
  },

  'procedures/getRequestDateTimeInfo': (state: S) => (
    procedureID: string,
  ): string | undefined => {
    const procedure = state.procedures[procedureID];
    if (!procedure || !procedure.dateInfo) return;

    return procedureDateInfo(procedure.dateInfo);
  },

  'procedures/getFormattedProcedureTimeInstruction': (state: S) => (
    procedureID: string,
  ): string | undefined => {
    const procedure = state.procedures[procedureID];
    if (!procedure || !procedure.dateInfo) return;

    const timeZone = procedure.dateInfo?.[0]?.dateInstruction?.timeZone;
    let formattedTimeZoneDisplay;
    if (timeZone) {
      formattedTimeZoneDisplay = dayjs().tz(timeZone).format('z');
    }

    return `${procedureDateInfo(procedure.dateInfo)} ${formattedTimeZoneDisplay}`;
  },

  'procedures/getProcedureZendeskTicketID': (state: S) => (
    procedureID: string,
  ) => {
    // TODO: remove typecasting once zendesk is implemented
    const procedure = state.procedures[procedureID];
    if (!procedure || !(procedure as any).zendeskTicketID) return;

    return (procedure as any).zendeskTicketID ?? 'some zendesk link' as string;
  },

  'procedures/getProcedureFriendlyStatus': (state: S, getters: AnyGetters, rootState: RootState) => (
    procedureID: string,
  ): ProcedureFriendlyInfo | undefined => {
    const procedure = state.procedures[procedureID];
    if (!procedure) return;

    const waypointAction = extraProceduresGetters[
      'procedures/getProcedurePatientWaypointAction'
    ](state, getters, rootState)(procedureID);
    const waypoint = rootState.itineraries.waypoints?.[waypointAction?.waypointID ?? ''];

    let dateInfo: string | undefined;
    let timeInfo: string | undefined;
    if (waypoint?.date) {
      dateInfo = `Scheduled for ${formatDate(waypoint?.date, 'MM/DD/YYYY')}`;
      timeInfo = `at ${formatDate(waypoint?.date, 'h:mm a')}`;
    } else if (procedure.dateInfo) {
      dateInfo = procedureDateInfo(procedure.dateInfo);
      timeInfo = getTimeInstructionDetailsString(procedure);
    }

    return {
      color: procedure.friendlyStatus?.color ?? StatusColor.RED,
      friendlyStatus: procedure.friendlyStatus?.label ?? 'Unknown status',
      dateInfo,
      timeInfo,
    };
  },

  'procedures/getProceduresProductInfo': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): (procedures: ByID<ElementTimestamp> | undefined) => ProductInfo | undefined =>
    (procedures: ByID<ElementTimestamp> | undefined) => {
      if (!procedures) return;
      const bookingProcedures = Object.keys(procedures)
        .map((procedureID: string) => state.procedures[procedureID]);

      const bookingTasks: (Task | undefined)[] = bookingProcedures
        .map((procedure) => !procedure ? undefined
          : rootState.variables.variables?.tasks[procedure.taskID]);
      const bookingProducts: (Product | undefined)[] = bookingProcedures
        .reduce<string[]>((previous, current) => previous.concat(Object.keys(current?.products ?? {})), [])
        .map((productID: string) => rootState.variables.variables?.products?.[productID]);

      return bookingProducts[0]?.info ?? bookingTasks[0]?.info;
    },

  'procedures/getPatientOnProcedure': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (
    /** Procedure object or ID */
    procedure: Procedure | string,
  ): EncryptedUser | null => {
    const procedureObj = typeof procedure === 'string'
      ? state.procedures?.[procedure]
      : procedure;
    const { bookingID } = procedureObj ?? {};
    if (!bookingID) return null;
    const booking = rootState.bookings.bookings[bookingID] ?? null;
    const patientID = booking?.patientID;

    if (!patientID) return null;
    return rootState.users.users?.[patientID] ?? null;
  },
};

const proceduresModuleExtension = {
  actions: extraProceduresActions,
  getters: extraProceduresGetters,
};

export const proceduresModule: ExtendedCustomModule<
  ProceduresModule<RootState, ExtendedUsersGetters & ExtendedOrdersGetters>,
  typeof proceduresModuleExtension
> = initProceduresModule(proceduresModuleExtension);

export type ExtendedProceduresModule = typeof proceduresModule;
export type ExtendedProceduresMutations = ExtendedProceduresModule['mutations'];
export type ExtendedProceduresActions = ExtendedProceduresModule['actions'];
export type ExtendedProceduresGetters = ExtendedProceduresModule['getters'];
