import { AlgoliaIndexSuffix, Role, ShiftOffer } from '@caresend/types';
import { ExtendedCustomModule, UsersModule, UsersState, initUsersModule } from '@caresend/ui-components';
import { stripAlgoliaProperties } from '@caresend/utils';
import update from 'immutability-helper';

import { searchWithDispatch } from '@/database/algolia/store';
import type { CustomActionContext } from '@/store/model';

type S = UsersState;

const extraUsersMutations = {
  'users/SET_SHIFT_OFFER': (state: S,
    {
      shiftOffer,
      userID,
      shiftID,
    }: {shiftOffer: ShiftOffer; userID: string; shiftID: string }) => {
    state.users = update(state.users, {
      [userID]: {
        shiftOffers: { $set: update(state.users[userID]?.shiftOffers ?? {}, {
          [shiftID]: { $set: shiftOffer },
        }) },
      },
    });
  },
};

type ExtraUsersActionContext = CustomActionContext<'users', S>

export type ExtraUsersActions = {
  'users/fetchNurses': (
    context: ExtraUsersActionContext,
    payload?: {
      query: string;
    },
  ) => Promise<void>;
}

const extraUsersActions: ExtraUsersActions = {
  'users/fetchNurses': async ({ commit, dispatch }, payload) => {
    const algoliaNurses = await searchWithDispatch(dispatch, {
      query: payload?.query,
      index: AlgoliaIndexSuffix.USERS,
      params: {
        filters: `role:${Role.NURSE}`,
      },
    });
    const nurses = algoliaNurses.map(stripAlgoliaProperties);

    commit('users/SET_USERS', nurses);
  },
};

export const usersModuleExtension = {
  mutations: extraUsersMutations,
  actions: extraUsersActions,
};

export const usersModule: ExtendedCustomModule<
  UsersModule,
  typeof usersModuleExtension
> = initUsersModule(usersModuleExtension);

export type ExtendedUsersModule = typeof usersModule;

export type ExtendedUsersMutations = ExtendedUsersModule['mutations'];
export type ExtendedUsersActions = ExtendedUsersModule['actions'];
export type ExtendedUsersGetters = ExtendedUsersModule['getters'];
