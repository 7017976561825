export interface CoordinateBounds {
  minLat: number;
  minLng: number;
  maxLat: number;
  maxLng: number;
}

export const getLocationNumericFilters = (
  prefix: string,
  coords: CoordinateBounds,
): string[] => [
  `${prefix}.lat:${coords.minLat} TO ${coords.maxLat}`,
  `${prefix}.lng:${coords.minLng} TO ${coords.maxLng}`,
];
