import { EncryptedUser } from '@caresend/types';
import { initSentry, isProd } from '@caresend/ui-components';
import Vue from 'vue';

import { isLocal } from '@/data/urls';
import { SEGMENT_TOKEN, SENTRY_DSN } from '@/database/credentials';

/**
 * Sentry
 */
export const initializeSentry = () => {
  initSentry(isProd, {
    Vue,
    dsn: !isLocal ? SENTRY_DSN : undefined,
    release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}@${process.env.VUE_APP_CI_COMMIT_SHORT_SHA}`,
  });
};

/**
 * Segment
 */

export const initSegment = () => {
  window.analytics.load(SEGMENT_TOKEN);
};

export const identifyAuthenticatedUserSegment = (user: EncryptedUser) => {
  const { id, info, role } = user;
  const { firstName, lastName, email } = info;

  const name = `${firstName} ${lastName}`;
  window.analytics.identify(id, {
    email,
    name,
    role,
  });
};
