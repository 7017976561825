/* eslint-disable no-param-reassign */
/** Param reassignment required */

/**
 * TODO: Move to utils after itinerary migration to ui-components
 */

import { isNullish, objectKeys } from '@caresend/utils';

type WithMaybeUndefinedChildren<T> = {
  [P in keyof T]: T[P] | undefined;
};

/**
 * To ensure that nulls are injected at runtime for optional values,
 * input objects should be provided with `undefined` child values.
 */
export type InjectedNulls<T> = {
  [P in keyof Required<T>]: undefined extends T[P]
    ? Required<T>[P] | null
    : Required<T>[P];
};

/**
 * Inject nulls into an objects properties that are possibly undefined
 *
 * A suitable usecase is initializing a reactive object for optimized
 * vDOM updates.
 */
export const injectNulls = <T>(
  obj: WithMaybeUndefinedChildren<T>,
): InjectedNulls<T> => {
  const newObj = {} as InjectedNulls<T>;

  type RC = undefined extends T[keyof T]
    ? NonNullable<T[keyof T]> | null
    : NonNullable<T[keyof T]>;
  objectKeys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      newObj[key] = null as RC;
    } else {
      newObj[key] = obj[key] as RC;
    }
  });

  return newObj;
};

/**
 * Used to clean and object to set values of:
 * `undefined`, empty objects/arrays, empty
 * strings to `null` recursively.
 */
export const injectNullsDeep = (
  obj: Record<string, any>,
  recursionBlockerKeys: string[] = [],
  level = 0,
): Record<string, any> | null => {
  if (isNullish(obj)) return null;
  if (obj !== null && typeof obj === 'object') {
    Object.keys(obj).forEach((k) => {
      const key = k as keyof typeof obj;
      if (isNullish(obj[key])) {
        obj[key] = null;
      } else if (typeof obj[key] === 'string' && obj[key] === '') {
        obj[key] = null;
      } else if (
        (Array.isArray(obj[key]) && obj[key].length === 0)
        || (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        obj[key] = null;
      } else if (recursionBlockerKeys.includes(key) && level < 2) {
        /** Prevent infinite recursive object size */
        obj[key] = null;
      } else {
        obj[key] = injectNullsDeep(obj[key], recursionBlockerKeys, level + 1);
      }
    });
  }
  return obj;
};
