<template>
  <transition
    name="down-up"
    mode="out-in"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'TransitionDownUp',
});
</script>

<style lang="sass" scoped>
.down-up-enter-active, .down-up-leave-active
  transition: opacity $cs-transition-length-default, transform $cs-transition-length-default

.down-up-enter, .down-up-leave-to
  opacity: 0
  transform: translateY(20px)
</style>
