import { ExtendedCustomModule, InsuranceModule, initInsuranceModule } from '@caresend/ui-components';

const insuranceModuleExtension = {
};

export const insuranceModule: ExtendedCustomModule<
  InsuranceModule,
  typeof insuranceModuleExtension
> = initInsuranceModule(insuranceModuleExtension);

export type ExtendedInsuranceModule = typeof insuranceModule;

export type ExtendedInsuranceMutations = ExtendedInsuranceModule['mutations'];
export type ExtendedInsuranceActions = ExtendedInsuranceModule['actions'];
export type ExtendedInsuranceGetters = ExtendedInsuranceModule['getters'];
