import { AlgoliaIndexSuffix, ByID, Shift, ShiftTools } from '@caresend/types';
import { getStartOfUTCDate, isNullish } from '@caresend/utils';
import dayjs from 'dayjs';

import { AlgoliaSearch } from '@/store/modules/algolia/model';
import { AlgoliaShiftFilter, LocalShiftFilters, ShiftDateTimeInfo, ShiftVacancy } from '@/store/modules/shifts/model';

const getPastShiftsFilter = (): string => {
  const currentTimestamp = getStartOfUTCDate(dayjs().valueOf());
  return `startTime.timestamp <= ${currentTimestamp}`;
};

const getUpcomingShiftsFilter = (): string => {
  const currentTimestamp = getStartOfUTCDate(dayjs().valueOf());
  return `startTime.timestamp >= ${currentTimestamp}`;
};

type ShiftAlgoliaSearch = AlgoliaSearch
  & { index: AlgoliaIndexSuffix.SHIFTS | AlgoliaIndexSuffix.SHIFTS_DESCENDING }

/**
 * SHIFTS_DESCENDING is sorted by startTime descending.
 *   - Past: Get the most recent 300 shifts.
 * SHIFTS is sorted by startTime ascending.
 *   - Upcoming: Get the next 300 shifts.
 */
export const getShiftsAlgoliaSearch = (
  algoliaFilter: AlgoliaShiftFilter,
): ShiftAlgoliaSearch => {
  switch (algoliaFilter) {
    case AlgoliaShiftFilter.PAST:
      return {
        index: AlgoliaIndexSuffix.SHIFTS_DESCENDING,
        params: { filters: getPastShiftsFilter() },
      };
    case AlgoliaShiftFilter.UPCOMING:
      return {
        index: AlgoliaIndexSuffix.SHIFTS,
        params: { filters: getUpcomingShiftsFilter() },
      };
  }
};

export const getShiftVacancy = (shift: Shift): ShiftVacancy =>
  isNullish(shift.userID) ? ShiftVacancy.VACANT : ShiftVacancy.TAKEN;

export const validateShiftStateData = (
  dateTimeInfo: ShiftDateTimeInfo | undefined,
  serviceRegionIDs: string[], taskIDs: string[],
) => {
  const baseErrorMessage = 'There was an issue creating this shift.';
  if (!dateTimeInfo?.startTime.timestamp) throw Error(`${baseErrorMessage} No start time.`);
  if (!dateTimeInfo?.endTime.timestamp) throw Error(`${baseErrorMessage} No end time.`);
  if (serviceRegionIDs.length === 0) throw Error(`${baseErrorMessage} No service regions.`);
  if (taskIDs.length === 0) throw Error(`${baseErrorMessage} No tasks.`);

  return {
    dateTimeInfo,
    serviceRegionIDs,
    taskIDs,
  };
};

export const cleanShiftStateData = (serviceRegionIDs: string[], taskIDs: string[]) => {
  const serviceRegions: ByID<boolean> = {};
  serviceRegionIDs.forEach((id) => {
    serviceRegions[id] = true;
  });

  const tasks: ByID<string> = {};
  taskIDs.forEach((id) => {
    tasks[id] = id;
  });

  return {
    serviceRegions,
    tasks,
  };
};

export const filterShiftsLocally = (
  shifts: Shift[],
  localFilters: LocalShiftFilters,
): Shift[] => {
  const noFiltersActive = !localFilters.vacancy;
  if (noFiltersActive) return shifts;

  const filteredShifts = shifts.filter((shift) => {
    const { vacancy } = localFilters;
    if (vacancy.length) return vacancy.includes(getShiftVacancy(shift));
    return true;
  });
  return filteredShifts;
};

export const initShiftTools = (): ShiftTools => ({
  centrifuge: false,
});
