import { isProd } from '@caresend/ui-components';

export const isLocal = process.env.NODE_ENV === 'development';

const webProEnvURL = isProd ? 'https://pro.caresend.com/' : 'https://caresend-dev.web.app/';
export const webProURL = isLocal ? 'https://localhost:8080/' : webProEnvURL;

const patientEnvURL = isProd ? 'https://patient.caresend.com/' : 'https://caresend-patient-dev.web.app/';
export const patientURL = isLocal ? 'https://localhost:8081/' : patientEnvURL;

const baseURL = 'https://caresend.com/';

/** @deprecated */
export const zendeskTicketBaseURL = 'https://caresendsupport.zendesk.com/agent/tickets/';

export const faqURL = `${baseURL}#FAQ`;
