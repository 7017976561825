import { isProd } from '@caresend/ui-components';

export const MIXPANEL_TOKEN = isProd
  ? 'bb3de5ef409478bdf34d0eb527fc7bbb'
  : '12beb9a782cd55d83cdfbb872945d7e0';

export const PLACES_TOKEN = 'AIzaSyCLcCBd6iK37JUMngt6Z7ZM6zUdh7Gq9oY';

export const ALGOLIA_APP_ID = '734Q94UIDR';

export const SEGMENT_TOKEN = isProd
  ? '70Uh6xJVnnCvWB81nLLclCxRnIqUmttv'
  : 'xsrsljY3GSC3Ju5W8Zs2TYRwbYGULg8V';

export const SENTRY_DSN = 'https://00206570648b4506850b8b1afff00451@o878771.ingest.sentry.io/5835905';
