import { DevRouteName, OrderFlowRouteName, SchedulingFlowRouteName } from '@caresend/ui-components';
import { BaseRouteMeta } from '@caresend/ui-components/lib/types/src/router/model';
import { Location, Route, RouteMeta } from 'vue-router';

export interface CustomRouteMeta extends BaseRouteMeta {
  allowIncomplete?: boolean;
}

export enum DashboardRouteName {
  DATABASE_SCRIPTS = 'DatabaseScripts',
  EDIT_CITY = 'EditCity',
  FILES = 'Files',
  FILTERS = 'Filters',
  HOME = 'Home',
  INVENTORY = 'Inventory',
  LEGACY_ITINERARY = 'LegacyItinerary',
  LEGACY_ORDERS = 'LegacyOrders',
  LOCATIONS = 'Locations',
  LOGIN = 'Login',
  NURSES = 'Nurses',
  NURSE_DETAILS = 'NurseDetails',
  NURSE_DISPATCH = 'NurseDispatch',
  OFFICES = 'Offices',
  ORDER_PAGE = 'OrderPage',
  PLACE_GROUP_PAGE = 'PlaceGroupPage',
  PLACE_GROUP_TYPE_PAGE = 'PlaceGroupTypePage',
  PROCEDURES = 'Procedures',
  PROCEDURE_PAGE = 'ProcedurePage',
  PROCESSING_QUEUE = 'ProcessingQueue',
  QA = 'QA',
  SANDBOX = 'Sandbox',
  SHIFTS = 'Shifts',
  SHIFT_DETAILS = 'ShiftDetails',
  TASKS_LIST = 'TasksList',
  TASK_TYPES_LIST = 'TaskTypesList',
  TESTS = 'Tests',
  USERS = 'Users',
  RESCHEDULE_VISIT = 'RescheduleVisit',
  WAYPOINT_SUPPLY_LIST = 'WaypointSupplyList',
  RECRUITING_MAP = 'RecruitingMap',
}

export type RouteName = DashboardRouteName
| DevRouteName
| OrderFlowRouteName
| SchedulingFlowRouteName;

export const routeNames = {
  ...DashboardRouteName,
  ...DevRouteName,
  ...OrderFlowRouteName,
  ...SchedulingFlowRouteName,
};

export type LocationData = Omit<Location, 'name' | 'path'>

export interface RouteData {
  /**
   * We can avoid some runtime errors being thrown with our custom `push()` and
   * `replace()` error handling, but on initial load, those error handlers are not
   * used, and some internal errors trigger both the custom handlers and
   * `router.onError`. Use this to prevent handling errors more than once.
   */
  errorsHandled: boolean;
  route: Route;
  prevRoute?: Route;
}

/**
 * Returns a single route meta object composed by combining all of the meta
 * objects on each matching route.
 */
export const getMatchedMeta = (route: Route) =>
  route.matched.reduce<RouteMeta>((previousMeta, matched) => ({
    ...previousMeta,
    ...matched.meta,
  }), {});

/**
 * This matches the initial route loaded on router.currentRoute until the
 * correct route resolves.
 */
export const initialRoute: Route = {
  fullPath: '/',
  hash: '',
  matched: [],
  meta: {},
  name: null,
  params: {},
  path: '/',
  query: {},
};
