import {
  augmentRouter,
  getRoutesDev,
  getRoutesOrderFlow,
  getRoutesPortal,
  getRoutesSchedulingFlow,
} from '@caresend/ui-components';
import Vue from 'vue';
import VueRouter from 'vue-router';

import authGuard from '@/router/methods/auth';
import devGuard from '@/router/methods/dev';
import featureFlagGuard from '@/router/methods/featureFlags';
import loadingGuard from '@/router/methods/loading';
import versioning from '@/router/methods/versioning';
import { routes } from '@/router/routes';
import { RootState } from '@/store/model';

Vue.use(VueRouter);

/**
 * `initRouter` must not be called until feature flags are loaded.
 *
 * `augmentRouter` applies shared router logic that is not app-specific. It must
 * be called prior to using `getRouter`, `getRoute`, and `getPrevRoute`
 * functions.
 */
export const initRouter = () => {
  /**
   * Rather than exporting the router, use `getRouter()` to access the instance.
   */
  const router: VueRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      ...routes,
      ...getRoutesDev<RootState>(),
      ...getRoutesOrderFlow<RootState>(),
      ...getRoutesSchedulingFlow<RootState>(),
      ...getRoutesPortal(),
    ],
  });

  const { allowRoute } = authGuard(router);
  const { allowFeature } = featureFlagGuard(router);
  const { allowDevAccess } = devGuard(router);
  const { setLoading } = loadingGuard(router);
  const { checkVersion, handleChunkError } = versioning(router);

  router.onReady(checkVersion);
  router.onError(handleChunkError);

  router.beforeResolve(setLoading(true));
  router.beforeResolve(allowRoute);
  router.beforeResolve(allowFeature);
  router.beforeResolve(allowDevAccess);
  router.afterEach(setLoading(false));

  augmentRouter(router);
};
