import { createNullCache } from '@algolia/cache-common';
import { AuthMode } from '@algolia/client-common';
import { SearchClient } from '@algolia/client-search';
import { AlgoliaIndexSuffix, GetAlgoliaAPIKeyResponse } from '@caresend/types';
import { toastError } from '@caresend/ui-components';
import algoliasearch from 'algoliasearch';

import { ALGOLIA_APP_ID } from '@/database/credentials';
import { getSuperAdminAlgoliaAPIKey } from '@/database/firebase/API';
import { needsNewKey } from '@/store/modules/algolia/helpers';
import {
  AlgoliaActions,
  AlgoliaIndices,
  AlgoliaState,
  bookings,
  draftWaypoints,
  draftWaypointsDescending,
  feedback,
  itineraries,
  offices,
  places,
  procedures,
  shifts,
  shiftsDescending,
  supplyShipments,
  supplyTransfers,
  users,
  waypoints,
} from '@/store/modules/algolia/model';

type S = AlgoliaState;

const algoliaState: S = {
  client: null,
  indices: null,
  keyData: null,
};

const algoliaMutations = {
  'algolia/SET_CLIENT': (state: S, newClient: SearchClient | null) => {
    state.client = newClient;
  },

  'algolia/SET_INDICIES': (state: S, newIndices: AlgoliaIndices | null) => {
    state.indices = newIndices;
  },

  'algolia/SET_KEY_DATA': (state: S, newKeyData: GetAlgoliaAPIKeyResponse | null) => {
    state.keyData = newKeyData;
  },
};

const algoliaActions: AlgoliaActions = {
  'algolia/search': async (
    { dispatch, state },
    { index, params, query },
  ) => {
    try {
      if (needsNewKey(state.keyData)) {
        await dispatch('algolia/updateApiKey');
      }
      const searchResponse = await state.indices?.[index]?.search(
        query ?? '',
        { hitsPerPage: 300, ...params },
      );
      if (!searchResponse) throw new Error(`Missing search index ${index}`);
      return searchResponse.hits;
    } catch (error) {
      toastError(error);
      throw error;
    }
  },

  'algolia/updateApiKey': async ({ commit }) => {
    try {
      const keyData = await getSuperAdminAlgoliaAPIKey();

      const client = algoliasearch(ALGOLIA_APP_ID, keyData.key, {
        responsesCache: createNullCache(),
        authMode: AuthMode.WithinQueryParameters,
      });

      const indices: AlgoliaIndices = {
        [AlgoliaIndexSuffix.BOOKINGS]: client.initIndex(bookings),
        [AlgoliaIndexSuffix.FEEDBACK]: client.initIndex(feedback),
        [AlgoliaIndexSuffix.DRAFT_WAYPOINTS]: client.initIndex(draftWaypoints),
        [AlgoliaIndexSuffix.DRAFT_WAYPOINTS_DESCENDING]: client.initIndex(draftWaypointsDescending),
        [AlgoliaIndexSuffix.ITINERARIES]: client.initIndex(itineraries),
        [AlgoliaIndexSuffix.OFFICES]: client.initIndex(offices),
        [AlgoliaIndexSuffix.PLACES]: client.initIndex(places),
        [AlgoliaIndexSuffix.PROCEDURES]: client.initIndex(procedures),
        [AlgoliaIndexSuffix.SHIFTS]: client.initIndex(shifts),
        [AlgoliaIndexSuffix.SHIFTS_DESCENDING]: client.initIndex(shiftsDescending),
        [AlgoliaIndexSuffix.SUPPLY_SHIPMENTS]: client.initIndex(supplyShipments),
        [AlgoliaIndexSuffix.SUPPLY_TRANSFERS]: client.initIndex(supplyTransfers),
        [AlgoliaIndexSuffix.USERS]: client.initIndex(users),
        [AlgoliaIndexSuffix.WAYPOINTS]: client.initIndex(waypoints),
      };
      commit('algolia/SET_CLIENT', client);
      commit('algolia/SET_INDICIES', indices);
      commit('algolia/SET_KEY_DATA', keyData);
    } catch (error) {
      toastError('There was a problem setting up Algolia search.');
      commit('algolia/SET_CLIENT', null);
      commit('algolia/SET_INDICIES', null);
      commit('algolia/SET_KEY_DATA', null);
      throw error;
    }
  },
};

const algoliaGetters = {
};

export type AlgoliaGetters = typeof algoliaGetters;

export const algoliaModule = {
  state: algoliaState,
  mutations: algoliaMutations,
  actions: algoliaActions,
  getters: algoliaGetters,
};

export type AlgoliaModule = typeof algoliaModule;
