import {
  DbRef,
  EncryptedOrder,
  GetUserOrdersRequest,
  Order,
  OrderData,
  PaginationRequest,
} from '@caresend/types';
import {
  ExtendedCustomModule,
  NestedOrdersMode,
  OrdersModule,
  OrdersState,
  PaginationInfo,
  firebaseUnbind,
  getUserOrdersRequest,
  initOrdersModule,
  toastErrorAndReport,
} from '@caresend/ui-components';
import { updateInArray } from '@caresend/utils';

import { initPaginationInfo } from '@/functions/general';
import type { CustomActionContext, RootState } from '@/store/model';
import { ExtendedOfficesActions, ExtendedOfficesMutations } from '@/store/modules/offices';
import { ExtendedUsersActions, ExtendedUsersMutations } from '@/store/modules/users';
import { ExtendedVariablesGetters } from '@/store/modules/variables';

interface ExtraOrdersState {
  /** Orders showing on the /orders route. */
  masterOrderList: {
    /** Timestamp to prevent data loading race condition */
    latestRequest: number;
    list: OrderData[];
    isLoading: boolean;
    paginationInfo: PaginationInfo;
  };
}

export type ExtendedOrdersState = OrdersState & ExtraOrdersState;

type S = ExtendedOrdersState;

export type ExtraOrdersActionContext = CustomActionContext<'orders', S>

export type ExtraOrdersActions = {
  'orders/fetchMasterOrders': (
    context: ExtraOrdersActionContext,
    payload: {
      mode: NestedOrdersMode;
      paginationRequest: PaginationRequest;
    },
  ) => Promise<void>;

  'orders/unbindOrder': (
    context: ExtraOrdersActionContext,
    orderID: string,
  ) => Promise<void>;
}

const initMasterOrderList = () => ({
  latestRequest: 0,
  list: [],
  isLoading: true,
  paginationInfo: initPaginationInfo(),
});

const extendedOrdersState: ExtraOrdersState = {
  masterOrderList: initMasterOrderList(),
};

const extendedOrdersMutations = {
  'orders/RESET_MASTER_ORDERS': (state: S) => {
    state.masterOrderList = initMasterOrderList();
  },

  'orders/SET_MASTER_ORDERS_LATEST_REQUEST': (state: S, timestamp: number) => {
    state.masterOrderList.latestRequest = timestamp;
  },

  'orders/SET_MASTER_ORDERS_LIST': (state: S, list: OrderData[]) => {
    state.masterOrderList.list = list;
  },

  'orders/SET_MASTER_ORDERS_LOADING': (state: S, isLoading: boolean) => {
    state.masterOrderList.isLoading = isLoading;
  },

  'orders/SET_MASTER_ORDERS_PAGINATION_TOTAL': (state: S, total: number) => {
    state.masterOrderList.paginationInfo.total = total;
  },

  'orders/SET_ORDER': (state: S, order: Order | EncryptedOrder) => {
    state.orders = {
      ...state.orders,
      [order.id]: order,
    };
  },

  /**
   * Used to locally update the value for `schedulingSent` on the send for
   * scheduling modal.
   */
  'orders/SET_SCHEDULING_SENT_TO_NOW_IN_MASTER_ORDERS': (state: S, orderID: string) => {
    const updatedList = updateInArray(
      state.masterOrderList.list,
      ({ id }) => id === orderID,
      (oldOrderData) => ({ ...oldOrderData, schedulingSent: Date.now() }),
    );
    if (updatedList) state.masterOrderList.list = updatedList;
  },
};

const extendedOrdersActions: ExtraOrdersActions = {
  'orders/fetchMasterOrders': async ({ commit, state }, { mode, paginationRequest }) => {
    commit('orders/RESET_MASTER_ORDERS');
    const requestTimestamp = Date.now();
    commit('orders/SET_MASTER_ORDERS_LATEST_REQUEST', Date.now());
    const { page, limit } = paginationRequest;

    let isFulfilled: boolean | undefined;
    if (mode === NestedOrdersMode.FULFILLED_ORDERS_DASHBOARD) isFulfilled = true;
    if (mode === NestedOrdersMode.PENDING_ORDERS_DASHBOARD) isFulfilled = false;

    const requestData: GetUserOrdersRequest = {
      includeDraftWaypointsDataInOrderData: true,
      includeOrderFlowDataInOrderData: true,
      includeBookingsDataInOrderData: true,
      includeOfficeDataInOrderData: true,
      includeProceduresDataInBookingData: true,
      includeDraftWaypointDataInProcedureData: true,
      includeWaypointActionsDataInProcedureData: true,
      includeWaypointDataInWaypointActionData: true,
      includeNurseDataInProcedureData: true,
      includePrescriberDataInOrderData: true,
      includePatientDataInBookingData: true,
      filters: { isFulfilled },
      page,
      limit,
    };

    try {
      const response = await getUserOrdersRequest(requestData);

      if (requestTimestamp === state.masterOrderList.latestRequest) {
        commit('orders/SET_MASTER_ORDERS_LIST', response.orders);
        commit('orders/SET_MASTER_ORDERS_PAGINATION_TOTAL', response.total);
        commit('orders/SET_MASTER_ORDERS_LOADING', false);
      }
    } catch (error) {
      toastErrorAndReport(error);
      commit('orders/SET_MASTER_ORDERS_LOADING', false);
    }
  },

  'orders/unbindOrder': async (_context, orderID) => {
    const path = `${DbRef.ORDERS}/${orderID}`;
    await firebaseUnbind(path);
  },
};

const extendedOrdersGetters = {

};

export const ordersModuleExtension = {
  state: extendedOrdersState,
  mutations: extendedOrdersMutations,
  actions: extendedOrdersActions,
  getters: extendedOrdersGetters,
};

export const ordersModule: ExtendedCustomModule<
  OrdersModule<
    RootState,
    ExtendedUsersMutations & ExtendedOfficesMutations,
    ExtendedOfficesActions & ExtendedUsersActions,
    ExtendedVariablesGetters
  >,
  typeof ordersModuleExtension
> = initOrdersModule(ordersModuleExtension);

export type ExtendedOrdersModule = typeof ordersModule;
export type ExtendedOrdersMutations = ExtendedOrdersModule['mutations'];
export type ExtendedOrdersActions = ExtendedOrdersModule['actions'];
export type ExtendedOrdersGetters = ExtendedOrdersModule['getters'];
