import {
  ByID,
  DbRef,
  Draft,
  ProcedureContact,
  Shipment,
  Sku,
  SupplyItem,
  SupplyShipmentShopifyInfo,
} from '@caresend/types';
import {
  ExtendedCustomModule,
  SuppliesModule,
  SuppliesState,
  getValueOnce,
  initSuppliesModule,
  toastError,
  toastSuccess,
} from '@caresend/ui-components';
import { getSupplyItemPath } from '@caresend/utils';

import { createShopifyOrder } from '@/database/firebase/API';
import type { CustomActionContext, RootState } from '@/store/model';
import { ExtendedProceduresMutations } from '@/store/modules/procedures';

type S = SuppliesState;

type ExtraSuppliesActionContext = CustomActionContext<'supplies', S>

type ExtraSuppliesActions = {
  'supplies/createShopifyOrder': (
    context: ExtraSuppliesActionContext,
    orderInfo: {
      supplyShipment: Draft<Shipment>;
      primaryContact: ProcedureContact | undefined;
      procedureID: string | undefined;
    },
  ) => Promise<SupplyShipmentShopifyInfo | undefined>;

  'supplies/fetchSkus': (
    context: ExtraSuppliesActionContext,
  ) => Promise<void>;

  'supplies/fetchSupplyItemByID': (
    context: ExtraSuppliesActionContext,
    supplyItemID: string,
  ) => Promise<void>;

  'supplies/fetchSupplyItems': (
    context: ExtraSuppliesActionContext,
  ) => Promise<void>;
};

const extraSuppliesActions: ExtraSuppliesActions = {
  'supplies/createShopifyOrder': async (_, { supplyShipment, primaryContact, procedureID }) => {
    try {
      const { shippingAddress, shippingRecipient: recipient } = supplyShipment;
      if (!shippingAddress || !recipient) throw Error('Missing shipping address or recipient');

      const shopifyInfo = await createShopifyOrder({ recipient, shippingAddress, primaryContact, procedureID });

      toastSuccess('Shopify order created successfully');
      return shopifyInfo;
    } catch (err) {
      toastError(err);
    }
  },

  'supplies/fetchSkus': async ({ commit }) => {
    const skusPath = `${DbRef.SKUS}`;
    const skus = await getValueOnce<ByID<Sku>>(skusPath);
    commit('supplies/SET_SKUS', skus);
  },

  'supplies/fetchSupplyItems': async ({ commit }) => {
    const supplyItemsPath = `${DbRef.SUPPLY_ITEMS}`;
    const supplyItems = await getValueOnce<ByID<SupplyItem>>(supplyItemsPath);

    commit('supplies/SET_SUPPLY_ITEMS', supplyItems);
  },

  'supplies/fetchSupplyItemByID': async ({ commit }, supplyItemID) => {
    const supplyItem = await getValueOnce<SupplyItem>(getSupplyItemPath(supplyItemID));
    if (supplyItem) {
      commit('supplies/SET_SUPPLY_ITEMS', {
        [supplyItemID]: supplyItem,
      });
    }
  },
};

const suppliesModuleExtension = {
  actions: extraSuppliesActions,
};

export const suppliesModule: ExtendedCustomModule<
  SuppliesModule<RootState, ExtendedProceduresMutations>,
  typeof suppliesModuleExtension
> = initSuppliesModule(suppliesModuleExtension);

export type ExtendedSuppliesModule = typeof suppliesModule;

export type ExtendedSuppliesMutations = ExtendedSuppliesModule['mutations'];
export type ExtendedSuppliesActions = ExtendedSuppliesModule['actions'];
export type ExtendedSuppliesGetters = ExtendedSuppliesModule['getters'];
