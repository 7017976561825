import {
  ByID,
  DbRef,
  EncryptedUser,
  MaybeEncrypted,
  Shipment,
} from '@caresend/types';
import {
  AppModule,
  AppState,
  ConsumingApp,
  ExtendedCustomModule,
  initAppModule,
  unwrapMaybeEncryptedData,
} from '@caresend/ui-components';
import { WriteCallbacks, objectFilter } from '@caresend/utils';
import cloneDeep from 'lodash.clonedeep';

import type { RootState } from '@/store/model';
import { ExtendedVariablesActions } from '@/store/modules/variables';

export type ExtendedAppState = AppState & {
  loadingIDs: ByID<boolean>;
};

type S = ExtendedAppState;

const appState: Partial<ExtendedAppState> = {
  loadingIDs: {},
};

export const extendedAppMutations = {
  'app/SET_LOADING_IDS': (state: S, loadingIDs: ByID<boolean>) => {
    state.loadingIDs = objectFilter(loadingIDs, (id) => id);
  },
};

const extendedGetters = {
  'app/getDefaultFetchWithRelationshipsCallbacks': (_s: any, _g: any) => (
    commit: any,
  ): WriteCallbacks => ({
    [DbRef.WAYPOINTS]: (waypoints) =>
      commit?.('itineraries/SET_WAYPOINTS', waypoints),
    [DbRef.WAYPOINT_ACTIONS]: (waypointActions) =>
      commit?.('itineraries/SET_WAYPOINT_ACTIONS', waypointActions),
    [DbRef.PLACE_ACCOUNTS]: (placeAccounts) =>
      commit?.('places/SET_PLACE_ACCOUNTS', placeAccounts),
    [DbRef.PLACE_GROUPS]: (placeGroups) =>
      commit?.('places/SET_PLACE_GROUPS', placeGroups),
    [DbRef.PROCEDURES]: (procedures) =>
      commit?.('procedures/SET_PROCEDURES', procedures),
    [DbRef.BOOKINGS]: (bookings) =>
      commit?.('bookings/SET_BOOKINGS', bookings),
    [DbRef.ITINERARIES]: (itineraries) =>
      commit?.('itineraries/MERGE_ITINERARIES', itineraries),
    [DbRef.ORDERS]: (orders) =>
      commit?.('orders/SET_ORDERS', orders),
    [DbRef.SUPPLY_TRANSFERS]: (supplyTransfers) =>
      commit?.('supplies/SET_SUPPLY_TRANSFERS', supplyTransfers),
    [DbRef.SHIPMENTS]: async (shipments) => {
      if (!Object.values(shipments)) return;

      const decryptedShipments: ByID<Shipment> = {};
      await Promise.all(
        Object.values(shipments).map(
          async (shipment) => {
            const shippingRecipient = await unwrapMaybeEncryptedData(shipment.shippingRecipient);
            decryptedShipments[shipment.id] = { ...shipment, shippingRecipient };
          },
        ),
      );
      commit?.('supplies/SET_SHIPMENTS', decryptedShipments);
    },
    [DbRef.DRAFT_WAYPOINTS]: (draftWaypoints) =>
      commit?.('draftWaypoints/SET_DRAFT_WAYPOINTS', draftWaypoints ?? {}),
    [DbRef.SHIFTS]: (shifts) =>
      commit?.('shifts/SET_SHIFTS', shifts),
    [DbRef.USERS]: (users) =>
      commit?.('users/SET_USERS', Object.values(cloneDeep(users)) as EncryptedUser[]),
    loadingIDs: (loadingIDs) =>
      commit?.('app/SET_LOADING_IDS', loadingIDs),
  }),
  'app/getIsLoading': (state: S) => (input: MaybeEncrypted<string>) =>
    !!state.loadingIDs[typeof input === 'string' ? input : input.id]
  ,
};

const appModuleExtension = {
  state: appState,
  mutations: extendedAppMutations,
  getters: extendedGetters,
};

export const appModule: ExtendedCustomModule<
  AppModule<RootState, ExtendedVariablesActions>,
  typeof appModuleExtension
> = initAppModule(ConsumingApp.DASHBOARD, appModuleExtension);

export type ExtendedAppModule = typeof appModule;

export type ExtendedAppMutations = ExtendedAppModule['mutations'];
export type ExtendedAppActions = ExtendedAppModule['actions'];
export type ExtendedAppGetters = ExtendedAppModule['getters'];
