import { ExtendedCustomModule, SchedulingFlowModule, initSchedulingFlowModule } from '@caresend/ui-components';

import type { RootState } from '@/store/model';

const schedulingFlowModuleExtension = {
};

export const schedulingFlowModule: ExtendedCustomModule<
  SchedulingFlowModule<RootState>,
  typeof schedulingFlowModuleExtension
> = initSchedulingFlowModule(schedulingFlowModuleExtension);

export type ExtendedSchedulingFlowModule = typeof schedulingFlowModule;

export type ExtendedSchedulingFlowGetters = ExtendedSchedulingFlowModule['getters'];
