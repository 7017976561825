import { Procedure, Waypoint, WaypointAction, WithFriendlyStatus } from '@caresend/types';
import { getStore } from '@caresend/ui-components';
import { nullishFilter } from '@caresend/utils';

export const getProceduresByOrderID = (
  orderID: string,
): WithFriendlyStatus<Procedure>[] => {
  const store = getStore();
  const order = store.state.orders.orders[orderID];
  const bookings = Object.values(order?.bookings ?? {}).map(
    (booking) => store.state.bookings.bookings[booking.id],
  ).filter(nullishFilter);
  const procedures = bookings.map((booking) => Object.values(
    booking.procedures ?? {},
  ).map((procedure) => store.state.procedures.procedures[procedure.id])
    .filter(nullishFilter)).flat();
  return procedures;
};

export const getWaypointActionsByOrderID = (
  orderID: string,
): WaypointAction[] => {
  const store = getStore();
  const procedures = getProceduresByOrderID(orderID);
  const waypointActions = procedures.map(
    (procedure) => Object.values(procedure.waypointActions ?? {},
    ).map(
      (waypointAction) => store.state.itineraries.waypointActions?.[waypointAction.id])
      .filter(nullishFilter),
  ).flat();
  return waypointActions;
};

export const getWaypointsByOrderID = (
  orderID: string,
): Waypoint[] => {
  const store = getStore();
  const waypointActions = getWaypointActionsByOrderID(orderID);
  const waypoints = waypointActions.map((waypointAction) =>
    waypointAction.waypointID ? store.state.itineraries.waypoints?.[waypointAction.waypointID] : undefined,
  ).filter(nullishFilter);
  return waypoints;
};
