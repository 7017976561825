import { Role, UserStatus } from '@caresend/types';
import { clearEncryptionCache, firebaseAuth } from '@caresend/ui-components';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';

import { getMatchedMeta, routeNames } from '@/router/model';

export default (router: VueRouter) => ({
  allowRoute: (
    to: Route,
    from: Route,
    next: NavigationGuardNext<Vue>,
  ) => {
    // Using router.app.$store avoids circular dependencies if using the router
    // within store modules.
    const { authRequired, allowIncomplete } = getMatchedMeta(to);
    const user = router.app.$store.getters['auth/getUser'];
    if (authRequired) {
      const rejectAppAccess = !user || (user.role !== Role.SUPERADMIN && user.role !== Role.RECRUITER);
      const rejectStatusAccess = !allowIncomplete && user?.status === UserStatus.INCOMPLETE;

      if (rejectAppAccess || rejectStatusAccess) {
        const redirect = to.fullPath;

        if (from.name === routeNames.LOGIN) {
          return router.app.$store.dispatch('app/updateRouteLoading', false);
        }

        clearEncryptionCache();
        firebaseAuth.signOut();

        const query: Dictionary<string> = { redirect };
        if (allowIncomplete) {
          query.allowIncomplete = String(allowIncomplete);
        }

        return next({
          name: routeNames.LOGIN,
          query,
        });
      }
    }
    return next();
  },
}
);
